import React, {useState} from 'react';
import {getCurrentDate, getDayIndex} from './dateTime.js';
import '../css/reports.css';


function Reports (props) {
    

    // const _date1 = props.date1 ? props.date1 : getCurrentDate(-7);
    const _date1 = props.date1 ? props.date1 : getCurrentDate();
    const _date2 = props.date2 ? props.date2 : getCurrentDate();
    const _day_index = ((props.day_index === undefined) || (props.day_index === -1)) ? getDayIndex(getCurrentDate()) : props.day_index;

    const [dateFrom, setDateFrom] = useState(_date1);
    const [dateTo, setDateTo] = useState(_date2);
    const [day_index, setDay_index] = useState(_day_index);
    //let day_index = getDayIndex(dateTo);
    


    // handle data chenge
    function handleOnChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        
        // 
        if (name === "date-to") {
            if (value < dateFrom) 
                document.getElementById("date-from").value = value;
            setDateTo(value);
            
        }

        if (name === "date-from") {
            if (value > dateTo) 
                document.getElementById("date-to").value = value; 
            setDateFrom(value);
            
        }    

        if (name === "days-week") {
            //console.log("DI", value);
            setDay_index (parseInt(value)); 
        } 

        const new_data = {
            date1: dateFrom,
            date2: dateTo,
            day_index: day_index
        };

        if (name === "date-from") new_data.date1 = value;
        if (name === "date-to") new_data.date2 = value;
        if (name === "days-week") new_data.day_index = parseInt(value);
        props.changeAppState(new_data);
    }
    
    const wastageReport = (event) => {

        // move to page "Wastage Report"
        props.loadPage("Wastage_report", 
            {
                date1: dateFrom, 
                date2: dateTo,
                day_index: day_index
            }
        );   
        event.preventDefault();
    }

    const salesReport = (event) => {

        // move to page "Sales Report"
        props.loadPage("Sales_report", 
            {
                date1: dateFrom, 
                date2: dateTo,
                day_index: day_index
            }
        );   
        event.preventDefault();
    }

    const recommendations = (event) => {;

        // move to page "Recommendation Report"
        props.loadPage("Recommendations_report", 
            {
                date1: getCurrentDate(),
                date2: dateTo,
                day_index: day_index
            }
        );   
        event.preventDefault();
    }

    const bakingRs = (event) => {
        // move to bakingRs report
        props.loadPage("Baking_Recommntations_Print", 
            {
                date1: dateFrom,
                date2: dateTo,
                day_index: day_index
            }
        );
    }

    // { ...(day_index === 0)? "checked" : "" }    
    return (
        <div className='r-page'>
            <div className="bg-green">
                <h2 className="date white mg-top-10">Reports</h2>
            </div>
    
                    <form className='r-form'>
                        <fieldset className="r-period">
                            <legend>Period:</legend>
        
                            <label htmlFor="date-from">From:</label>
                            <input type="date" id="date-from" name="date-from" defaultValue={dateFrom} onChange={(e) => handleOnChange(e)} />

                            <label htmlFor="date-to">To:</label>
                            <input type="date" id="date-to" name="date-to" defaultValue={dateTo} onChange={(e) => handleOnChange(e)} />

                        </fieldset>
                        <button className='empty-white-button bg-light-grey'  onClick={(e) => { salesReport(e) }}><div>Generate sales report</div></button>
                        <button className='empty-black-button bg-middle-grey' onClick={(e) => { wastageReport(e) }}><div>Generate wastage report</div></button>
                        <button className='empty-green-button ' onClick={(e) => { bakingRs(e) }}>
                            <div className='text-align-middle'>
                                <img className="button-img" src={"./media/temp.png"} alt=" "/>
                                <div>Print baking recommendations</div>
                            </div>
                        </button>

                    </form>

                    <form className='r-form'>
                    <fieldset className="r-days-of-week">
                            <legend>Choose the day of week:</legend>
                            <input type="radio" id="0" name="days-week" value="0" onChange={(e) => handleOnChange(e)}  defaultChecked={ (day_index === 0)}/>
                            <label htmlFor="0">Monday</label>
                            <input type="radio" id="1" name="days-week" value="1" onChange={(e) => handleOnChange(e)}  defaultChecked={ (day_index === 1) }/>
                            <label htmlFor="1">Tuesday</label>
                            <input type="radio" id="2" name="days-week" value="2" onChange={(e) => handleOnChange(e)}  defaultChecked={ (day_index === 2) }/>
                            <label htmlFor="2">Wednesday</label>
                            <input type="radio" id="3" name="days-week" value="3" onChange={(e) => handleOnChange(e)}  defaultChecked={ (day_index === 3) }/>
                            <label htmlFor="3">Thursday</label>
                            <input type="radio" id="4" name="days-week" value="4" onChange={(e) => handleOnChange(e)}  defaultChecked={ (day_index === 4) }/>
                            <label htmlFor="4">Friday</label>
                            <input type="radio" id="5" name="days-week" value="5" onChange={(e) => handleOnChange(e)}  defaultChecked={ (day_index === 5) }/>
                            <label htmlFor="5">Saturday</label>
                            <input type="radio" id="6" name="days-week" value="6" onChange={(e) => handleOnChange(e)}  defaultChecked={ (day_index === 6) }/>
                            <label htmlFor="6">Sunday</label>
                        </fieldset>
                        <button className='empty-green-button bg-middle-grey' onClick={(e) => { recommendations(e) }}><div>Generate recommendations *</div></button>
                        <p className='light small'>(*) Calculated from <span className='italic bold'>current</span> date for 4 weeks period</p>
                    </form>
                </div> 

    );
}

export default Reports;