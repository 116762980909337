import React, {useState, useEffect} from "react";
import { products_obj, session_types, recommendations, 
    BAKED_TYPE_ID, WASTAGE_TYPE_ID, EXTRA_SESSION_TYPE_ID, 
    fetchOperations, addNewSession, deleteSession, updateAllOperations } from "./data";
import { getDayIndex } from './dateTime.js';

import ProductList from "./ProductList";
import ProductDisplay from './ProductDisplay';
import SquareButton from './SquareButton';
import Message from './Message';
import '../css/operations.css';


const data = {};

const Operations = (props) => {
    // props
    // date:             
    // time:            
    // session_id:      
    // session_type_id: 

    const [current_operation_type_id, setCurrent_operation_type_id ] = useState(1); // Baked
    const [flag_data_change, setFlag_data_change] = useState(true);
    const [flag_show_error_message, setFlag_show_error_message] = useState({show: false, message: ""});
    const [flag_show_save_message, setFlag_show_save_message] = useState(false);

    // set the session date
    let time = props.time;
    if( (props.session_type_id != EXTRA_SESSION_TYPE_ID) && (session_types.length > 0) )
        session_types.forEach(st => {
            if( st.session_type_id === props.session_type_id )
                time = st.time;
    }); 
    
    // get operations from database
    useEffect(() => {

        if( Object.values(products_obj).length <= 0 ) return;
        const day_index = getDayIndex(props.date);
        let r = 0;

        // set all data to zero
        Object.values(products_obj).forEach((p) => {
            try { r = recommendations[p.product_id].rs["D" + day_index].quantity[props.session_type_id-1]; }
            catch { r = 0 }

            data[p.product_id] = {
                QTY1: {operation_id: -1, quantity: 0, new_quantity: 0}, // baked quantity
                QTY3: {operation_id: -1, quantity: 0, new_quantity: 0}, // wastage quantity
                R1: r, // recommendations for baked
                R3: 0
            } 
        });

        fetchOperations(props.session_id).then((response) =>{
            //console.log(response);
            if(response) {
                response.forEach((op) => {
                    //console.log(op);    
                    //console.log(data[op.product_id]); 
                    data[op.product_id]["QTY" + op.operation_type_id].operation_id = op.operation_id;
                    data[op.product_id]["QTY" + op.operation_type_id].quantity = op.quantity;
                    data[op.product_id]["QTY" + op.operation_type_id].new_quantity = op.quantity;
                });
            }

            // redraw the webpage for new data
            setFlag_data_change(!flag_data_change);
        });
    }, []);

    const assignData = (value, indexes) => {
        try {
            data[indexes.product_id]["QTY" + indexes.operation_type_id].new_quantity = value;
            //console.log(data[indexes.product_id]["QTY" + indexes.operation_type_id].new_quantity);
        } catch {};
    }

    const getData = ( indexes ) => {
        //console.log(data[indexes.product_id]["QTY" + indexes.operation_type_id].new_quantity);
        let result = {"quantity": 0};
        try {
            result = {"quantity": data[indexes.product_id]["QTY" + indexes.operation_type_id].new_quantity};
           
        } catch {};
        return result;
    }

    // get recommendation
    const getRs = ( indexes ) => {
        //console.log(data[indexes.product_id]["QTY" + indexes.operation_type_id].new_quantity);
        let result = 0;
        try {
            result = data[indexes.product_id]["R" + indexes.operation_type_id];
           
        } catch {};
        return result;
    }

    const checkRed = (indexes) => {
        try {
            const baked = data[indexes.product_id]["QTY1"].new_quantity;
            const wastage = data[indexes.product_id]["QTY3"].new_quantity;
            if (baked >= wastage) return ""; else return "red";
        } catch {}

        return "";
    }

    const getOperatonID = ( indexes ) => {
        let result = -1;
        try {
            result = data[indexes.product_id]["QTY" + indexes.operation_type_id].operation_id;
        } catch {}
        return result; 
    }

    const additionalText = (_operation_type_id) => {
        if(_operation_type_id === BAKED_TYPE_ID) return "at " + time;
        else if (_operation_type_id === WASTAGE_TYPE_ID) return "from " + time;
    }

    // get background
    const getBG = (_operation_type_id) => {
        if(_operation_type_id === BAKED_TYPE_ID ) return "bg-ochre";
        else if(_operation_type_id === WASTAGE_TYPE_ID ) return "bg-almost-white";
    }

        // get background
    const getStyles = (_operation_type_id) => {
        if(_operation_type_id === current_operation_type_id ) return "bb-none";
        else return "";
    }

    const toggleFlag_data_change = () => {
        setFlag_data_change(!flag_data_change);
    }

    // OPERATION BUTTONS
    // reset all quantities to zero 
    const resetToZero = () => {
        Object.values(data).forEach(op => {
            op["QTY" + current_operation_type_id].new_quantity = 0;
            // set westage to zero in case the bakes is set to zero
            if (current_operation_type_id === BAKED_TYPE_ID) {
                op["QTY" + WASTAGE_TYPE_ID].new_quantity = 0; 
            }
        });
        setFlag_data_change(!flag_data_change);
    }

    // accept all recommendations for all BAKE operations
    const acceptAllRecommendations = () => {
        if(current_operation_type_id === BAKED_TYPE_ID) {

            Object.values(data).forEach(op => {
                op["QTY" + BAKED_TYPE_ID].new_quantity = op["R" + BAKED_TYPE_ID];   
            });
            setFlag_data_change(!flag_data_change);
        }
    }

    // print the temperature check report
    const tempCheck = () => {
        const data_array = [];

        // save operations before t check print
        saveOperations().then((res) => {

            // create data for 
            for (const [key, p] of Object.entries(data)) {
                // construct data for the temp check
                // console.log(products_obj[key].name)
                
                data_array.push({
                    product_id: key,
                    name: products_obj[key].name,
                    category_id: products_obj[key].category_id,
                    baking_program_id: products_obj[key].baking_program_id,
                    baked_QTY :  p.QTY1.new_quantity,
                    wastage_QTY: p.QTY3.new_quantity,
                    r_QTY: p.R1 // recommendations
                });

            };
            data_array.sort((a, b) => {return a.category_id - b.category_id});

            // call temp Check
            props.loadPage("Temp_check", 
            {
                date:            props.date, 
                time:            props.time, 
                session_id:      props.session_id,
                session_type_id: props.session_type_id,
                data_array: data_array
            }); 
       });
    }

    const submit = async() => {
        const result = await saveOperations();
        if (result >= 0) exit();
    }

    const cancel = async() => {
        //chack any changes
        let marker = false;
        Object.values(data).forEach((p) => {
            if( p.QTY1.new_quantity != p.QTY1.quantity ) marker = true;
            if( p.QTY3.new_quantity != p.QTY3.quantity ) marker = true;
        });

        if (marker) {
            setFlag_show_save_message(true);
        } else exit();

    }

    // return to Today page
    const exit = () => {
        window.history.back()
    }

    // save changed data
    const saveOperations = async () => {
        console.log("... updating operations ...");
        // valid input check
        const errors = [];
        for (const [key, p] of Object.entries(data)) {
            if(p.QTY1.new_quantity < p.QTY3.new_quantity)
                errors.push(products_obj[key].name);
        };
        
        if (errors.length > 0) {
            // console.log(errors.length);
            setFlag_show_error_message({
                show: true,  
                message: "baked < wastage: " + errors.toString()});
            return -1;

        } else {
            console.log('saving...');
            // add or delete session
            let session_id = props.session_id;
            const session_type_id = props.session_type_id;
            const date = props.date; 
            const time = props.time;
            
            let marker = 0;
            Object.values(data).forEach((p) =>{  
                if (p.QTY1.new_quantity > 0) marker++; // baked
                if (p.QTY3.new_quantity > 0) marker++; // wastage
            });


            // add session to database in case of new session
            if( (session_id === -1) && ( marker > 0 )) {    
                let result = await addNewSession(session_type_id, date, time);
                session_id = result;
                console.log("Session inserted:", session_id);
            }
            
            // delete session from database if no items
            if( (session_id !== -1) && ( marker === 0 )) {
                let result = await deleteSession(session_id);
                session_id = -1;
                console.log("Session " + session_id + " deleted: ", result);
                
            }

            // change page's state
            if(props.session_id !== session_id) {
                const new_state = {
                    date: date,
                    time: time,
                    session_id: session_id,
                    session_type_id: session_type_id
                };
                props.changeAppState(new_state);
            }

            const data_array = [];
            // chack if there are any changes
            // 1 - baked
            // 3 - wastage
            const operation_types = [1, 3];       
            
            for (const [key, p] of Object.entries(data)) {

                operation_types.forEach(op_type => {
                           
                    if( p["QTY" + op_type].quantity != p["QTY" + op_type].new_quantity ) { 
                        const operation = {
                            operation_id: p["QTY" + op_type].operation_id,
                            session_id: session_id, 
                            operation_type_id: op_type, 
                            product_id: key,
                            quantity: p["QTY" + op_type].new_quantity,
                            type: ""
                        }
    
                        // delete or update or insert new operation
                        if (p["QTY" + op_type].quantity === 0) operation.type = "INSERT O";
                            else if (p["QTY" + op_type].new_quantity === 0) operation.type = "DELETE O";
                                else operation.type = "UPDATE O";

                        data_array.push(operation);
                    }
                });
            }
            if (data_array.length > 0 ) {
                await updateAllOperations(data_array); 
                return 1;
            } else return 2;

        }
    }

    // close erroe message dialog
    const closeErrorMessageDialog = () => {
        setFlag_show_error_message({ show: false, message: "" });
    }

    // close Save message dialog
    const closeSaveMessageDialog = () => {
        setFlag_show_save_message(false);
    }

    return (
        <div className='operations'>
            {/* PRODUCT DISPLAY */}
            <ProductDisplay 
                date = { props.date }
                time = { time }
                setData = { assignData } 
                getData = { getData }
                checkRed = { checkRed }
                toggleFlag_data_change = { toggleFlag_data_change }
                operation_type_id = { current_operation_type_id }
                />

            {/* THE LIST OF PRODUCTS */}
            <div className="product-display-container">
                <div className="operation-display-header-2">
                        <div key="b" className={"operation-tab bg-ochre "      + getStyles(BAKED_TYPE_ID)}   onClick={() => setCurrent_operation_type_id(BAKED_TYPE_ID)}>   <h4>Baked</h4>   <p className="small-text italic">{additionalText(BAKED_TYPE_ID)}</p></div>
                        <div key="w" className={"operation-tab bg-almost-white " + getStyles(WASTAGE_TYPE_ID)} onClick={() => setCurrent_operation_type_id(WASTAGE_TYPE_ID)}> <h4>Wastage</h4> <p className="small-text italic">{additionalText(WASTAGE_TYPE_ID)}</p></div>
                </div>
                <div className="operation-display">
                    {/* //"product-list " +  */}
                <div className={getBG(current_operation_type_id)}> 
                    <ProductList 
                        product_list = { products_obj }
                        setData = { assignData }
                        getData = { getData }
                        getID = { getOperatonID }
                        checkRed = { checkRed }
                        getRs = { getRs }
                        mode = "OPERATIONS"
                        indexes = { {operation_type_id: current_operation_type_id, flag_data_change: flag_data_change} }/>
                    </div>
                </div>
            </div>

            {/* OPERATION BUTTONS */}
            <div className="operation-buttons">

                <div className="operation-buttons-container">
                    <SquareButton 
                        key={'obtn'+ "Reset"} 
                        title={ "Reset to zero" } 
                        button_type={"Reset"}
                        onClick={() => resetToZero()}
                    />

                    <SquareButton 
                        key={'obtn'+ "Accept-all-rs"} 
                        title={ "Accept all Rs" } 
                        button_type={"Accept-all-rs"}
                        onClick = {() => acceptAllRecommendations()}
                    />
                    
                    <SquareButton 
                        key={'obtn'+ "Print-t-check"} 
                        title={ `Print t check` } 
                        button_type={"Print-t-check"}
                        onClick={() => tempCheck()}
                    />
                    
                </div>
                <div className="operation-buttons-container">
                <SquareButton 
                        key={'obtn'+ "Submit"} 
                        title={ "Submit" }         
                        button_type={"Submit"}
                        onClick={() => submit() }
 
                    />
                <SquareButton 
                        key={'obtn'+ "Cancel"} 
                        title={ "Cancel & Exit" } 
                        // date={ props.date } 
                        // time={ props.time }
                        // session_id = {props.session_id}
                        // session_type_id = {props.session_type_id}
                         
                        button_type={"Cancel"}
                        onClick={() => cancel() }
                        // page_name="Today"
                        // loadPage={this.props.loadPage})/>
                    />
                </div>
            </div>
            {/* MESSAGE WINDOW */}
            {/* error message dialog */}
            {flag_show_error_message.show ? 
                <Message 
                    title = "Incorrect input:"
                    message = { flag_show_error_message.message }
                    cancel_onClick = {closeErrorMessageDialog}
                    button_3="OK"
                    button_3_onClick={closeErrorMessageDialog}/>
            : ""}

            {/* save message dialog */}
            {flag_show_save_message ? 
                <Message 
                    title = "Save changes?"
                    message = ""
                    cancel_onClick = {closeSaveMessageDialog}
                    button_1="Exit without saving"
                    button_1_onClick={exit}
                    button_3="Save"
                    button_3_onClick={submit}/>
            : ""}
        </div>
        
    );

}

export default Operations;