import React, {useState, useEffect} from "react";
import { transformDate} from './dateTime.js';
import { categories,products_obj } from './data.js';
import Category from './Category';
import ProductThumbnail from './ProductThumbnail';

const ProductDisplay = (props) => {
    let html = [];
    let htmlProducts = [];
    let prods = [];

    const [operation_type_id, setOperation_type_id] = useState(props.operation_type_id);

    useEffect(() => {
        
        setOperation_type_id(props.operation_type_id);
    }, [props]);

    // construct the list of products
    if(products_obj.length == 0) return <div title="product-display">"No products to show"</div>;

    categories.forEach(c => {
        prods = [];
        htmlProducts = [];

        // select list of products for the category
        Object.values(products_obj).forEach((p) => {
            if(p.category_id === c.category_id) 
                prods.push(p);
        });

        if (prods.length > 0) {
            html.push(<Category key={ 'ctds'+ c.category_id } name={ c.name } category_id={ c.category_id } mode={"PD"}/>);
            prods.forEach(pr => {

                htmlProducts.push(<ProductThumbnail
                    key={"pr_th" + pr.product_id} 
                    product_id = { pr.product_id }
                    operation_type_id = { operation_type_id }
                    getData = { props.getData }
                    setData = { props.setData }
                    toggleFlag_data_change = { props.toggleFlag_data_change }
                    checkRed = { props.checkRed }
                   />);
            });
            html.push(<div key={"pdlc" + c.category_id} className="three-buttons-in-a-row" >{htmlProducts}</div>);
        }
    })

    return (
        <div  className="product-display-container">
            <div  className="product-display-header">
                <h2  className="date">{transformDate(props.date)}</h2>
                <h2  className="date">time: {props.time}</h2>
            </div>

            <div  className="product-display">{html}</div> 
        </div>
    );
}

export default ProductDisplay;