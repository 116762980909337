import '../css/header.css';
import React from 'react';
import {getCurrentDate, getCurrentTime} from './dateTime.js';

function Header(props) {
    const data = {
        date: getCurrentDate(),
        time: "",
        date1: null,
        date2: null,
        day_index: -1,
        data_array: [],
        session_id: -1,
        session_type_id: -1
    };



    // const data = props.data;
    // console.log(data);
    const user = props.user;
    // console.log(user.f_recommendations);
    const user_name = user.user_name;
    let f_today = "hidden";
    let f_previous_days = "hidden";
    let f_products = "hidden";
    let f_reports = "hidden";
    let f_help = "hidden";
    let f_logout = "hidden";
    let f_recommendations = "hidden";

    // chech the access to menu functions 
    if (user_name != "") {
        f_today = (user.f_today === 0) ? "hidden" : "";
        f_previous_days = (user.f_previous_days === 0) ? "hidden" : "";
        f_products = (user.f_products === 0) ? "hidden" : "";
        f_reports = (user.f_reports === 0) ? "hidden" : "";
        f_recommendations = (user.f_recommendations === 0) ? "hidden" : "";
        f_help = "";
        f_logout = "";    
    }

    return (
        <header className="header noprint">
            <nav className="nav">
                <div className="nav-links">
                    <a className={f_today} onClick={() => { window.history.back() }}>&lt;&lt;</a>
                    <a className={f_today} onClick={() => { window.history.forward() }}>&gt;&gt;</a>
                    <a className={"green " + f_today} onClick={() => props.loadPage("Today", data)}>Today</a>
                    <a className = {f_previous_days} onClick={() => props.loadPage("Previous_days", data)}>Archive</a>
                    <a className = {f_products} onClick={() => props.loadPage("Products", data)}>Products</a>
                    <a className = {f_reports} onClick={() => props.loadPage("Reports", data)}>Reports</a>
                    <a className = {f_recommendations} onClick={() => props.loadPage("Recommendations", data)}>R's</a>
                    {/* <a className = {f_help} onClick={() => props.loadPage("Help",                    data)}>Help</a> */}
                </div>
                <div className="logout-links">
                    <a className={f_logout} onClick={() => props.logout()}>Logout</a>
                </div>
            </nav>
        </header>
    );
  }
  
  export default Header;