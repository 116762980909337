import React, {useState, useEffect} from "react";

const ProductAvg = (props) => {

    // props:
    const { getData } = props

    const [data, setData] = useState(getData(props.indexes));
    const [indexes, setIndexes] = useState(props.indexes);

    // round the number to 2 digits
    const round_2 = (number) => {
        return (Math.round(number) === number) ? number : (number).toFixed(2);
    }

    // as soon as props changes, quntity and indexes also change
    useEffect (()=> {
        setData(getData(props.indexes));
        setIndexes(props.indexes);
        //console.log(getID(props.indexes));

    }, [props]);

        return (
            <div className="grid-3 bg-light-light-green">
                <div className="dark-grey">avg.b: <span className="rs-avg black">{ data.baked ? round_2(data.baked) : `0`}</span></div>
                <div className="dark-grey">avg.w: <span className="rs-avg black">{ data.wastage ? round_2(data.wastage): `0`}</span></div>
                <div><span className="rs-avg black">{ data.baked > 0? `(${Math.round(data.percent*100)}%)`: `` }</span></div>
            </div>
        );
}

export default ProductAvg;