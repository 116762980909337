
// date functions
// get current date
export function getCurrentDateSTR() {
    const current = new Date(); 
    return getDateFullString(current);
}

export function getCurrentDate(delta = 0) {
    const current = new Date(); 
    current.setDate(current.getDate() + delta);
    return getDateFormatString(current);
}

export function getCurrentTime() {
    const current = new Date(); 
    return getTimeFormatString(current);  
}
// get date DD/MM/YYYY

export function getReportFormatDate(date_str) {
  const year = date_str.substring(0, 4);
  const month = date_str.substring(5, 7);
  const day = date_str.substring(8);

  return `${day}/${month}/${year}`;
}

// get string representation of current date
// DAYOFWEEK, MONTH Date
function getDateFullString(current) {
    const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    //const monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
    
    return `${dayOfWeek[current.getDay()]}, ${current.getDate()} ${month[current.getMonth()]} ${current.getFullYear()}`;
}

// returns YYYY-MM-DD
export function getDateFormatString(current) {
    
    const month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    let day = current.getDate();
    if (day < 10) day = '0'+ day; else day = '' + day;
    const dateFormatted = `${current.getFullYear()}-${month[current.getMonth()]}-${day}`;
    return dateFormatted;
}

export function transformDate(date_str) {

    const year = date_str.substring(0, 4);
    const month = date_str.substring(5, 7);
    const day = date_str.substring(8);

    // console.log(date_str, year, month, day);
    const date_obj = new Date();
    date_obj.setDate(day);
    date_obj.setMonth(parseInt(month)-1);
    date_obj.setFullYear(year);
    //console.log(date_obj.getFullYear());


    // date_obj.setMonth();
    return getDateFullString(date_obj);   
}

// returns the name of the week day by index
export function getWeekDay(day_index) {
    if (day_index < 0 || day_index > 6) return "";
    const dayOfWeek = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];
    return dayOfWeek[ day_index ];
}

// formatted time: HH:MM
function getTimeFormatString(current){

  let hours  = current.getHours();
  //if (hours < 10) hours = "0" + hours;
  let minutes = current.getMinutes();
  if (minutes < 10) minutes = "0" + minutes;
  //console.log(hours, minutes);

  return `${hours}:${minutes}`;
} 

// get the index of current day 
// Monday = 0, ... Sunday = 6
export function getDayIndex(date_str="") {
    let year = ""; 
    let  month = "";
    let day = ""; 
    let date_obj = new Date();

    if (date_str !== "") {
        year = date_str.substring(0, 4);
        month = date_str.substring(5, 7);
        day = date_str.substring(8);

        date_obj.setDate(day);
        date_obj.setMonth(parseInt(month)-1);
        date_obj.setFullYear(year);
    }

    
    let day_of_week = date_obj.getDay() - 1;
    if (day_of_week === -1) day_of_week = 6;
    
    return day_of_week;
}


// get the picture path
export const getPicturePath = (photo) => {
    let photo_link = "";

    if (photo && photo !== '') 
            photo_link = "./media/" + photo;
        else 
            photo_link = "./media/pie-logo-4.png";

    return photo_link; 
}