import React from "react";

function SquareButton(props) {

    var className = "";
    var title_element = "";
    var img  = "";

    const button_type = props.button_type;

    // title_element = <div>{props.title}</div>

    if(button_type !== 'Print-t-check') 
        title_element = <div>{props.title}</div>
    else
        title_element = <div>Print t<sup>o</sup> check</div>;

        //Print-baking-Rs

    if (button_type === 'session')          className = "square-button" + " " + props.status + " session-button";
    if (button_type === 'session +')        className = "square-button" + " " + props.status + " session-button";
    if (button_type === 'Reset')            className = "square-button" + " " + "reset-button";
    if (button_type === 'Accept-all-rs')    className = "square-button" + " " + "reset-button";
    if (button_type === 'Print-t-check')    className = "square-button" + " " + "reset-button";
    if (button_type === 'Print-baking-Rs')  className = "square-button" + " " + "rs-button";
    if (button_type === 'Cancel')           className = "square-button" + " " + "cancel-button";
    if (button_type === 'Submit')           className = "square-button" + " " + "submit-button";
    if (button_type === 'Cancel_products')  className = "square-button" + " " + "cancel-button";
    if (button_type === 'Submit_products')  className = "square-button" + " " + "submit-button";

    if (button_type === 'Reset')
        img = <img className="square-button-img" src={"./media/reset-2.png"} alt=" "/>;

    if (button_type === 'Accept-all-rs')
        img = <img className="square-button-img" src={"./media/accept-all.png"} alt=" "/>;

    if (button_type === 'Print-t-check' || button_type === 'Print-baking-Rs') 
        img = <img className="square-button-img" src={"./media/temp.png"} alt=" "/>;

    //
    const handlerOnClick = (event) => {
        event.preventDefault();

        if ((button_type === "Cancel_products") || (button_type === "Submit_products") )
            props.onClick(props.product_id);
        else
            props.onClick(props.page_name, data);
    }
    //    
    const data = { 
        date:            props.date, 
        time:            props.time, 
        session_id:      props.session_id,
        session_type_id: props.session_type_id,
    }

    return <button className={ className } onClick={ (e) => handlerOnClick(e) }>{img}{title_element}</button>
}

export default SquareButton;