import React from "react";
import { checkUser } from './data.js';

function Login (props) {
    
    // click on login button
    function handleSubmit(e){
        e.preventDefault();

        const user_name = document.getElementById("username").value.trim();
        const password = document.getElementById("password").value.trim();
        let user; 

        if(user_name === "" || password === "") {
            document.getElementById("warning").innerHTML = "(*) Incorrect username or password.";
            return; 
        }

        // call fetch request to database
        checkUser(user_name, password).then((res) => {

            const access = res.access; 
            if (access) {
                document.getElementById("warning").innerHTML = "(*) Incorrect username or password.";
                return;            
            } 
            //
            user = res;
            props.login(user);
        });

    }
    // clean the warning message
    function cleanWarning() {
        const warning = document.getElementById("warning");
        if( warning.innerHTML !== "" ) warning.innerHTML = "";
    }

    return (
        <div className="login-page">
            <div className="green-panel">
                <h2 className="light italic grey">Pie Champion System</h2>
                <form className="login-form">
                    <h1 className="light white">Login</h1>
                    <input type="text" id="username" title="username" role="input" name = "username" placeholder="Username" onChange={() => cleanWarning()}/>
                    <input type="password" id="password" title="password" name="password" placeholder="Password" onChange={() => cleanWarning()}/>
                    <button className="bg-dark-grey white" title="login" onClick={(e) => handleSubmit(e)} ><div>Log in</div></button>
                </form>
                <h3 className="light italic grey">BP Onewa road</h3>
                <h3 className="red italic" id="warning"></h3>
            </div>
            <picture>
                <img src="./media/pies-two-heaps.png" alt="" />
            </picture>

        </div>
    );
}

export default Login;