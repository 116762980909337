import { getDayIndex } from './dateTime.js'

// global arrays
//export let products = [];
export let products_obj = {};
export let all_products_obj = {};
export let categories = [];
// export let product_tree = [];
export let session_types = []; 
export let operation_types = [];
export let providers = [];
export let baking_programs = [];
export let recommendations = {};
// global const
export let EXTRA_SESSION_TYPE_ID = 4;

export const BAKED_TYPE_ID = 1;
export const UNSOLD_TYPE_ID = 2;
export const WASTAGE_TYPE_ID = 3;


// export const HOST = 'http://localhost:5000';
// export const HOST = 'https://demo-pie-server.royal-train-design.com';
export const HOST = 'https://pie-server.royal-train-design.com';

// FUNCTIONS
// fetch list of product categories from database
export async function checkUser(_user_name, _password) {
  const url = HOST + '/check_user/' + _user_name + "/" + _password;

  try {
    let response = await fetch(url);
  
    if (response.status === 200) {
      let json = await response.json();
      //console.log("user: ", json);
      return json;
    } 
    else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
  } catch(err) { console.error(err); }
}


// fetch list of product categories from database
export async function fetchCategories() {
    const url = HOST + '/categories/';
    try {
      let response = await fetch(url);
    
      if (response.status === 200) {
        let json = await response.json();
        categories = json;
      } 
      else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
    } catch(err) { console.error(err); }
  }

// fetch list of product categories from database
export async function fetchBakingPrograms() {
  const url = HOST + '/baking_programs/';
  try {
    let response = await fetch(url);
  
    if (response.status === 200) {
      let json = await response.json();
      baking_programs = json;
    } 
    else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
  } catch(err) { console.error(err); }
}

// fetch list of product categories from database
export async function fetchProviders() {
  const url = HOST + '/providers/';
  try {
    let response = await fetch(url);
  
    if (response.status === 200) {
      let json = await response.json();
      providers = json;
    } 
    else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
  } catch(err) { console.error(err); }
}


//compile all product arrays 
function setProducts_obj(json) {
  //
  all_products_obj = {};
  products_obj = {};

  // products as object
  json.forEach((p) => {
    all_products_obj[p.product_id] = p;

    if (p.archive === 0) {
        products_obj[p.product_id] = p; 
    }
  });
}

  // fetch list of products from DB
export async function fetchProducts() {

    const url = HOST + '/product_list/';

    try {
      let response = await fetch(url);
    
      if (response.status === 200) {
        let json = await response.json();
        // products = json;

        setProducts_obj(json);

      } else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
      
    } catch(err) { console.error(err); }
}

// add new product to database
export async function addNewProduct(new_product) {
  //console.log(new_product); return;
  if(!new_product) return;
  if( isNaN(new_product.category_id) || new_product.category_id === undefined || new_product.category_id < 0) return;
  if( isNaN(new_product.baking_program_id) || new_product.baking_program_id === undefined || new_product.baking_program_id < 0) return;
  if( isNaN(new_product.provider_id) || new_product.provider_id === undefined || new_product.provider_id < 0) return;
    if( new_product.name === undefined || new_product.name === "")  return;   
    if( new_product.short_name === undefined || new_product.short_name === "")  return; 

    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    

    // fetch to post new product
    const request = new Request(HOST + '/product_list/', {method: 'POST', headers: headers, body: JSON.stringify(new_product), mode: 'cors'});

    try {
      let response = await fetch(request);
    
      if (response.status === 200) {
        let json = await response.json();
        //console.log(json);

        setProducts_obj(json);

        return true;
      } 
      else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }

    } catch(err) { console.error(err); }
}

// update product data to database
export async function updateProduct (product) {
  
  //console.log(product);
  if(!product) return;
  if( isNaN(product.category_id) || product.category_id === undefined || product.category_id < 0) return;
  if( isNaN(product.baking_program_id) || product.baking_program_id === undefined || product.baking_program_id < 0) return;
  if( isNaN(product.provider_id) || product.provider_id === undefined || product.provider_id < 0) return;
  if( product.name === undefined || product.name === "")  return;   
  if( product.short_name === undefined || product.short_name === "")  return; 
  //
  const headers = new Headers();
    headers.append('Content-Type', 'application/json');    

    const request = new Request(HOST + '/product_list/' + product.product_id, { method: 'PUT', headers: headers, body: JSON.stringify(product), mode: 'cors'} );  
    
    try {
      let response = await fetch(request);
    
      if (response.status === 200) {
        let json = await response.json();

        setProducts_obj(json);

        return true;
      } 
      else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }

    } catch(err) { console.error(err); }   
}

  //download list of session types from database
 export async function fetchSessionTypes() {
      const url = HOST + '/session_types';
        
      try {
        let response = await fetch(url);
      
        if (response.status === 200) {
          let json = await response.json();
          session_types = json;

        } else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
        
      } catch(err) { console.error(err); }
    }

  export function findExtraTypeID() {

            // find "extra" id#
            //console.log(session_types);
            session_types.forEach((st) => {
              if (st.time === 'extra')
                EXTRA_SESSION_TYPE_ID = st.session_type_id;
              console.log(st.time);
            });
            //console.log(EXTRA_SESSION_TYPE_ID);
  }


  // Fetch session by date 
  //download list of sessions from database
  export const fetchSessions = async (_date) => {
    const url = HOST + '/sessions/' + _date;
    try {
      let response = await fetch(url);
    
      if (response.status == 200) {
        let json = await response.json();
        return json;

      } else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
      
    } catch(err) { console.error(err); }
  }

  // add new session to database
  export async function addNewSession(session_type_id, date, time) {

    if( isNaN(session_type_id) || session_type_id === undefined || session_type_id < 0)  return;
    if( date === undefined || date === "")  return;   
    if( time === undefined || time === "")  return; 

    let calc_session_type_id = -1;
    if( session_type_id === EXTRA_SESSION_TYPE_ID ) {
        session_types.forEach(st => {
          if( time > st.time) calc_session_type_id = st.session_type_id;
        });
    } else calc_session_type_id = session_type_id;

    const day_of_week = getDayIndex(date);


    const theSession = {
      session_type_id: session_type_id, 
      calc_session_type_id: calc_session_type_id, 
      date: date, 
      time: time, 
      day_of_week: day_of_week
    };

    const headers = new Headers();
    headers.append('Content-type', 'application/json');

    // fetch to post new session
    const request = new Request(HOST + '/sessions', {method: 'POST', headers: headers, body: JSON.stringify(theSession), mode: 'cors'});

    try {
      let response = await fetch(request);
    
      if (response.status === 200) {
        let session = await response.json();
        const val = session[0];
        console.log(val["@session_id"]);
        return val["@session_id"];
        //return session[0].session_id;
      } 
      else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }

    } catch(err) { console.error(err); }
    
  }

  export async function deleteSession(session_id) {

    if(isNaN(session_id) || session_id === undefined ) return;
    console.log("session to delete:", session_id);
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');    

    const request = new Request(HOST + '/sessions/' + session_id, { method: 'DELETE', headers: headers, body: '', mode: 'cors'} );  
    
    try {
      let response = await fetch(request);
    
      if (response.status === 200) {
        let json = await response.json();
        return json;
      } 
      else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }

    } catch(err) { console.error(err); }   
  }

  // add new operation
  export async function addOperation(session_id, operation_type_id, product_id, quantity){

    if(isNaN(session_id) || session_id === undefined ) return;
    if(isNaN(operation_type_id) || operation_type_id === undefined ) return;
    if(isNaN(product_id) || product_id === undefined ) return;
    if(isNaN(quantity) || quantity === undefined ) return;
    //
    const theOperation = {
      session_id: session_id, 
      operation_type_id: operation_type_id, 
      product_id: product_id, 
      quantity: quantity, 
    };

    const headers = new Headers();
    headers.append('Content-type', 'application/json');

    // fetch to post new category
    const request = new Request(HOST + '/operations', {method: 'POST', headers: headers, body: JSON.stringify(theOperation), mode: 'cors'});

    try {
      let response = await fetch(request);
    
      if (response.status === 200) {
        let status = await response.json();
        return status;
      } 
      else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }

    } catch(err) { console.error(err); }


  }

  // delete operation by operation_id
  export async function deleteOperation (operation_id) {
    if(isNaN(operation_id) || operation_id === undefined  || operation_id < 0 ) return;

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');    

    const request = new Request(HOST + '/operations/' + operation_id, { method: 'DELETE', headers: headers, body: '', mode: 'cors'} );  
    
    try {
      let response = await fetch(request);
    
      if (response.status === 200) {
        let json = await response.json();
        return json;
      } 
      else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }

    } catch(err) { console.error(err); }   

  }

// update operation by id
export async function updateOperation (operation_id, quantity) {

  const theOperation = { 
    operation_id: operation_id, 
    quantity: quantity
  }  
  const headers = new Headers();
    headers.append('Content-Type', 'application/json');    

    const request = new Request(HOST + '/operations/' + operation_id, { method: 'PATCH', headers: headers, body: JSON.stringify(theOperation), mode: 'cors'} );  
    
    try {
      let response = await fetch(request);
    
      if (response.status === 200) {
        let json = await response.json();
        return json;
      } 
      else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }

    } catch(err) { console.error(err); }   

  }

  export async function updateAllOperations (data) {

    if (data.length === 0) {
      return ({"message": "no data"});
    }

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');    

    const request = new Request(HOST + '/all_operations/', { method: 'PUT', headers: headers, body: JSON.stringify(data), mode: 'cors'} );  
    
    try {
      let response = await fetch(request);
    
      if (response.status === 200) {
        let json = await response.json();
        return json;
      } 
      else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
    } catch(err) { return {"error": err}; }     
  }


  //get Baked/Wastage operations for wastage report
  export async function fetchBWOperations(dateFrom, dateTo){

    const url = HOST + '/wastage_report/' + dateFrom + '/' + dateTo;
        try {
            let response = await fetch(url);
        
            if (response.status === 200) {
            let json = await response.json();
            return json;
            } 
            else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
        } catch(err) { console.error(err); }
    }
  
  //get Baked/Wastage operations for sales report
  export async function fetchBWOperations_sales_report(dateFrom, dateTo){
    const url = HOST + '/sales_report/' + dateFrom + '/' + dateTo;

    try {
        let response = await fetch(url);
    
        if (response.status === 200) {
          let json = await response.json();
          //console.log(json);
          return json;
        } else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
    } catch(err) { console.error(err); }

  }


  export async function fetchRecommendations_report(dateFrom, day_index) {
    const url = HOST + '/recommendations_report/' + dateFrom + '/' + day_index;

    try {
      let response = await fetch(url);
  
      if (response.status === 200) {
        let json = await response.json();
        return json;
        
      } else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
  } catch(err) { console.error(err); }

}


// RECOMMENDATIONS
// fetch recommendations
export async function fetchRecommendations() 
{
  if( Object.values(products_obj) <= 0 ) return;
  //if(products.length <= 0) return;
  // recommendations = {};
  //
  //products.forEach( p => {
  Object.values(products_obj).forEach((p) => {

    recommendations[p.product_id] = {
      product_id: p.product_id, 
      rs: {
            "D0": {id:-1, quantity: [0, 0, 0]},
            "D1": {id:-1, quantity: [0, 0, 0]},
            "D2": {id:-1, quantity: [0, 0, 0]},
            "D3": {id:-1, quantity: [0, 0, 0]},
            "D4": {id:-1, quantity: [0, 0, 0]},
            "D5": {id:-1, quantity: [0, 0, 0]},
            "D6": {id:-1, quantity: [0, 0, 0]}
          }
    };
  });

  

  // fetch from database
  const url = HOST + '/recommendations/';
  try {
    let response = await fetch(url);
    
    if (response.status === 200) {
      let json = await response.json();
      
      //console.log(json);
      // put into recommendations array 
      json.forEach((r) => {
        // select only active records
        //console.log(r.product_id);
        if (all_products_obj[r.product_id].archive === 0) {
          recommendations[r.product_id].rs["D" + r.day_id].id = r.recommendation_id;
          recommendations[r.product_id].rs["D" + r.day_id].quantity[0] = r.quality_session_1; // 6:00
          recommendations[r.product_id].rs["D" + r.day_id].quantity[1] = r.quality_session_2; // 11:00
          recommendations[r.product_id].rs["D" + r.day_id].quantity[2] = r.quality_session_3; // 15:00
        }
      });

    } 
    else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
  } catch(err) { console.error(err); }
  // console.log(recommendations);

}

// calculate recommendations
export async function calculateRecommendations() 
{
  // fetch from database
  const url = HOST + '/average/';
  try {
    let response = await fetch(url);
    
    if (response.status === 200) {
      let json = await response.json();
      return json;
    } 
    else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
  } catch(err) { console.error(err); }

  //console.log(recommendations);
}

// update or insert recommendations
export async function updateAllRecommendations (data) {

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');    

  // console.log(data);
  const request = new Request(HOST + '/all_recommendations/', { method: 'PUT', headers: headers, body: JSON.stringify(data), mode: 'cors'} );  
  
  try {
    let response = await fetch(request);
  
    if (response.status === 200) {
      let json = await response.json();
      return json;
    } 
    else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
  } catch(err) { return {"error": err}; }     
}

export const fetchOperations = async (session_id) => {

  if(session_id === -1) return;

  const url = HOST + '/operations/by_session/' + session_id; 
  try {
      let response = await fetch(url);
      
      // construct data
      if (response.status === 200) {
          const json = await response.json();
          return json;

      } else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
    } catch(err) { console.error(err); }
}



