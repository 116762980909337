import React from "react";
import { useEffect, useState } from "react";
import { session_types, EXTRA_SESSION_TYPE_ID } from './data.js';
import { getCurrentTime } from './dateTime.js';

import SquareButton from './SquareButton';

const SessionButtonsObj = (props) => {
    const [sessions, setSessions] = useState(props.sessions);

    useEffect(() => {
        setSessions(props.sessions); 
    },[props])

    // render the component
    const html = () => {
        const html = [];
        let is_active = '';
        let button_title  = '';

        if ((!sessions) || (!session_types)) return "No sessions to show";

        session_types.forEach((st) => {

            button_title = st.time;
            is_active = '';

            if (sessions.length > 0) {
                sessions.forEach((s) => {
                    if(s.session_type_id === st.session_type_id) {
                        is_active = 'active'; 
                        if(st.time === 'extra') button_title += ' ' + s.time;
                            
                        html.push( <SquareButton 
                            key={'s'+ button_title} 
                            title={ button_title } 
                            status={ is_active } 
                            button_type="session" 
        
                            date = { props.date } 
                            time = { s.time }
                            session_id = { s.session_id }
                            session_type_id = {st.session_type_id}
                            
                            onClick={ props.loadPage }
                            page_name={ "Operations" }
                            loadPage={ props.loadPage }/>);
                        }    
                });
            }

            if(is_active === "" && st.session_type_id !== EXTRA_SESSION_TYPE_ID) 
                html.push( <SquareButton 
                    key={'s'+ button_title} 
                    title={ button_title } 
                    status={ is_active } 
                    button_type="session" 

                    date = { props.date } 
                    time = { st.time }
                    session_id = { -1 }
                    session_type_id = {st.session_type_id}
                    
                    onClick={ props.loadPage }
                    page_name={ "Operations"}
                    loadPage={ props.loadPage }/>);
        });
        html.push(<SquareButton 
            key={'s'+ 'plus'} 
            title={ '+' } 
            status={ 'plus' } 
            button_type="session +"
            
            date={ props.date } 
            time={ getCurrentTime() }
            session_id = { -1 }
            session_type_id = { EXTRA_SESSION_TYPE_ID }
  
            onClick={props.loadPage}
            page_name="Operations"
            loadPage={props.loadPage}/>);

        return html;
    }


    return (
        <div className="three-buttons-in-a-row">
            { html() }
        </div>
    );
}

export default SessionButtonsObj;