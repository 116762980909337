import React from 'react';
import { fetchBWOperations, products_obj, categories } from './data.js';
import { getReportFormatDate } from './dateTime.js';
import '../css/temp-check.css';

class WastageReport extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          date1: props.date1, 
          date2: props.date2,
          data: []
        }
        //console.log(this.state.date1, this.state.date2);
        this.fetchData = this.fetchData.bind(this);
      };

      componentDidMount() {
        this.fetchData(this.state.date1, this.state.date2)
      }

      render(){
        let period = "";
        // display period or day
        if(this.state.date1 === this.state.date2) 
            period = <h3><span className="light">{"date: "}</span> {getReportFormatDate(this.state.date1)} </h3>
        else 
            period = <h3><span className="light">{"period: from "}</span> { getReportFormatDate(this.state.date1) } <span className="light">{" to "}</span>{ getReportFormatDate(this.state.date2) }</h3>
            
        return(
          //
          <div className='r-report wr-wastage-report landscape'>
              {/* BUTTONS BACK AND PRINT */}
              <div className='print-button-container noprint'>
                  <button className='empty-black-button' onClick={(e) => { window.history.back() } }><div>{"<< Back"}</div></button>
                  <button className='empty-green-button' onClick={(e) => { window.print()} }><div>Print</div></button>
              </div>

              {/* HEADER */}
              <div className="wr-header"><h3>Wastage report</h3>{period}</div>

              {/* TABLE */}
              { this.tableHeader() }
              { this.tableBodyDaily() }
              
          </div>
        );
      }
    
      // Table body 
      fetchData(dateFrom, dateTo) { 
        fetchBWOperations(dateFrom, dateTo).then ( response => {
            //console.log(response);

            // array of products
            let data = [];
            
            
            Object.values(products_obj).forEach(pr => {
              
                data.push({
                    category_id: pr.category_id,
                    category_name: categories[pr.category_id].name,
                    product_id: pr.product_id,
                    product_name: pr.name,
                    price: pr.purchase_price,
                    b1:0, w1:0,
                    b2:0, w2:0,
                    b3:0, w3:0,
                    b_total: 0, w_total: 0
                });
                
            });
            data.sort((a, b) => a.category_id - b.category_id );
            
            // response[0] because the stored procedure return [2]
            // response[0] is data, response[1] is service info
            response[0].forEach(r => {   
              data.forEach(d => {
                    if(d.product_id === r.product_id) {
                      d.b1 = parseInt(r.b1); d.b2 = parseInt(r.b2); d.b3 = parseInt(r.b3);
                      d.w1 = parseInt(r.w1); d.w2 = parseInt(r.w2); d.w3 = parseInt(r.w3);
                      d.b_total = d.b1 + d.b2 + d.b3;
                      d.w_total = d.w1 + d.w2 + d.w3;
                    }
                });
            });

            this.setState({
              data: data
            });
        });
      }

    // Table body 
    tableBodyDaily() {
          const html = [];
          let category_id = -1;
          let category_name = "";
          let product_index = 1;

          // category total
          let w_total = 0; let b_total = 0; let s_total = 0; 
          let w1 = 0; let b1 = 0; let s1 = 0;
          let w2 = 0; let b2 = 0; let s2 = 0;
          let w3 = 0; let b3 = 0; let s3 = 0;

          // table total
          let wt_total = 0; let bt_total = 0; let st_total = 0; 
          let wt1 = 0; let bt1 = 0; let st1 = 0;
          let wt2 = 0; let bt2 = 0; let st2 = 0;
          let wt3 = 0; let bt3 = 0; let st3 = 0;

          if (!this.state.data || this.state.data.length === 0) return "No data";
          //console.log(this.state.data);
          this.state.data.forEach((d) => {
              if (category_id === -1 ) {
                category_id = d.category_id;
                category_name = d.category_name;
                
              }

              if (category_id !== d.category_id) {
                // display category section
                const c = {
                  category_id: category_id,
                  name: category_name, 
                  w_total: w_total, b_total: b_total, s_total: s_total,
                  w1: w1, w2: w2, w3: w3,
                  b1: b1, b2: b2, b3: b3,
                  s1: s1, s2: s2, s3: s3,
                }
                //
                
                html.push(this.renderCategory(c));

                // calculate table totals
                wt1+= w1; wt2+= w2; wt3+= w3; wt_total+= w_total;
                bt1+= b1; bt2+= b2; bt3+= b3; bt_total+= b_total;
                st1+= s1; st2+= s2; st3+= s3; st_total+= s_total;

                // reset totals
                w_total = b_total = s_total = 0; 
                w1 = b1 = s1 = 0;
                w2 = b2 = s2 = 0;
                w3 = b3 = s3 = 0;
                category_id = d.category_id;
                category_name = d.category_name;
              }
              // display
              html.push(this.renderProduct(d, product_index));
              product_index ++;

              // sum
              w1+= d.w1;         w2+= d.w2;         w3+= d.w3;         w_total+= d.w_total;
              b1+= d.b1;         b2+= d.b2;         b3+= d.b3;         b_total+= d.b_total;
              s1+=      d.w1*d.price; 
              s2+=      d.w2*d.price;
              s3+=      d.w3*d.price; 
              s_total+= d.w_total*d.price;
          });
          
          // display last category
          const c = {
            category_id: category_id,
            name: category_name, 
            w_total: w_total, b_total: b_total, s_total: s_total,
            w1: w1, w2: w2, w3: w3,
            b1: b1, b2: b2, b3: b3,
            s1: s1, s2: s2, s3: s3,
          }
          html.push(this.renderCategory(c));
          
          // calculate table totals
          wt1+= w1; wt2+= w2; wt3+= w3; wt_total+= w_total;
          bt1+= b1; bt2+= b2; bt3+= b3; bt_total+= b_total;
          st1+= s1; st2+= s2; st3+= s3; st_total+= s_total;

          // display table totals
          const t = {
            category_id: "*",
            name: "TOTAL", 
            w_total: wt_total, b_total: bt_total, s_total: st_total,
            w1: wt1, w2: wt2, w3: wt3,
            b1: bt1, b2: bt2, b3: bt3,
            s1: st1, s2: st2, s3: st3,
          }
          html.push(this.renderCategory(t,"TOTAL"));
          

          //const num = parseInt(Math.random*100);
          return (<div key={"wr_tbl"} className=''>{html}</div>);
  }

    // Table category row
    renderCategory(c, t) {
      const element = [];
      element.push(
          <div key={"wr_ct" + c.category_id} 
              className={`tc-100 wr-div-6 tc-bdr-left tc-bdr-right bg-light-grey bold tc-bdr-bottom`}>
              <p className='tc-bdr-right '></p>
              <p className='tc-bdr-right ta-left'>{ c.name }</p>

              {/* TOTAL */}
              <div className='wr-div-3 tc-bdr-right'>
                  <p className='tc-thin-bdr-right'>{c.w_total}</p>
                  <p className='tc-thin-bdr-right'>{(c.s_total).toFixed(2)}</p>
                  <p>{ c.b_total === 0 ? "" : Math.round(c.w_total / c.b_total * 100) + "%" }</p>
              </div>
              
              {/* 6:00 */}
              <div className='wr-div-3  tc-bdr-right'>
                    <p className='tc-thin-bdr-right'>{c.w1}</p>
                    <p className='tc-thin-bdr-right'>{(c.s1).toFixed(2)}</p>
                    <p >{ c.b1===0 ? "" : Math.round(c.w1 / c.b1 * 100) + "%" }</p>
              </div>

              {/* 11:00 */}
              <div className='wr-div-3  tc-bdr-right'>
                    <p className='tc-thin-bdr-right'>{c.w2}</p>
                    <p className='tc-thin-bdr-right'>{(c.s2).toFixed(2)}</p>
                    <p >{ c.b2===0 ? "" : Math.round(c.w2 / c.b2 * 100) + "%" }</p>
              </div>
              {/* 15:00 */}
              <div className='wr-div-3'>
                    <p className='tc-thin-bdr-right'>{c.w3}</p>
                    <p className='tc-thin-bdr-right'>{(c.s3).toFixed(2)}</p>
                    <p >{ c.b3===0 ? "" : Math.round(c.w3 / c.b3 * 100) + "%" }</p>
              </div>
          </div>);

          
          if (t !== "TOTAL")
            element.push(
            <div key={"wr_cte" + c.category_id} className='tc-100 wr-div-6 tc-bdr-left tc-bdr-right tc-thin-bdr-bottom'>
                <p className='tc-bdr-right'>#</p>
                <p className='tc-bdr-right ta-left'></p>
                {/* TOTAL */}
                
                <div className='wr-div-3 tc-bdr-right'>
                    <p className='tc-thin-bdr-right'></p>
                    <p className='tc-thin-bdr-right'></p>
                    <p className='bg-light-grey'></p>
                </div>
                
                {/* 6:00 */}
                <div className='wr-div-3  tc-bdr-right'>
                      <p className='tc-thin-bdr-right'></p>
                      <p className='tc-thin-bdr-right'></p>
                      <p className='bg-light-grey'></p>
                </div>
                {/* 11:00 */}
                <div className='wr-div-3  tc-bdr-right'>
                      <p className='tc-thin-bdr-right'></p>
                      <p className='tc-thin-bdr-right'></p>
                      <p className='bg-light-grey'></p>
                </div>
                {/* 15:00 */}
                <div className='wr-div-3 tc-bdr-right'>
                      <p className='tc-thin-bdr-right'></p>
                      <p className='tc-thin-bdr-right'></p>
                      <p className='bg-light-grey'></p>
                </div>
            </div>);  
    return element;
  }
    
    // Table product row
    renderProduct(d, index) {
      return (
          <div key={"wr_pr" + index} className='tc-bdr-left tc-bdr-right wr-div-6'>
              <p className='tc-bdr-right tc-thin-bdr-bottom'>{ index }</p>
              <p className='tc-bdr-right tc-thin-bdr-bottom ta-left'>{ d.product_name }</p>
              {/* TOTAL */}
              
                <div className='wr-div-3 tc-thin-bdr-bottom tc-bdr-right'>
                    <p className='tc-thin-bdr-right'>{d.w_total}</p>
                    <p className='tc-thin-bdr-right'>{(d.w_total > 0 ) ? (d.w_total * d.price).toFixed(2) : ""}</p>
                    <p className='bg-light-grey'>{ d.w_total===0 ? "" : Math.round(d.w_total / d.b_total * 100) + "%" }</p>
                </div>
              
              {/* 6:00 */}
              <div className='wr-div-3 tc-bdr-right tc-thin-bdr-bottom'>
                  <p className='tc-thin-bdr-right'>{ d.w1 }</p>
                  <p className='tc-thin-bdr-right'>{ (d.w1 > 0) ? (d.w1 * d.price).toFixed(2) : "" }</p>
                  <p className='bg-light-grey'>{ d.w1===0 ? "" : Math.round( d.w1 / d.b1 * 100) + "%" }</p>
              </div>
              {/* 11:00 */}
              <div className='wr-div-3 tc-bdr-right tc-thin-bdr-bottom'>
                  <p className='tc-thin-bdr-right'>{ d.w2 }</p>
                  <p className='tc-thin-bdr-right'>{ (d.w2 > 0) ? (d.w2 * d.price).toFixed(2) : "" }</p>
                  <p className='bg-light-grey'>{ d.w2===0 ? "" : Math.round(d.w2 / d.b2 * 100) + "%" }</p>
              </div>
              {/* 15:00 */}
              <div className='wr-div-3 tc-bdr-right tc-thin-bdr-bottom'>
                  <p className='tc-thin-bdr-right'>{ d.w3 }</p>
                  <p className='tc-thin-bdr-right'>{ (d.w3 > 0) ? (d.w3 * d.price).toFixed(2) : "" }</p>
                  <p className='bg-light-grey'>{ d.w3===0 ? "" : Math.round(d.w3 / d.b3 * 100) + "%" }</p>
              </div>
          </div>
      );
  }

     // TABLE HEADER
    tableHeader() {
      return (
          <section className='wr-div-6 tc-bdr-top tc-bdr-bottom tc-bdr-right tc-bdr-left bg-light-grey'>
                  <h4 className='tc-bdr-right wr-h4'>#</h4>
                  <h4 className='tc-bdr-right wr-h4'>Products</h4>
                  <div>
                      <div className='tc-bdr-right' style={{width: '100%'}}><h4 className='wr-h4'>TOTAL WASTAGE</h4></div>
                      <div className='wr-div-3 tc-thin-bdr-top tc-bdr-right'>
                          <h4 className='tc-thin-bdr-right'>QTY</h4>
                          <h4 className='tc-thin-bdr-right'>$$</h4>
                          <h4>%%</h4>
                      </div>
                  </div>
                  <div>
                      <div className='tc-bdr-right' style={{width: '100%'}}><h4 className='wr-h4'>Wastage from 6:00</h4></div>
                      <div className='wr-div-3 tc-thin-bdr-top tc-bdr-right'>
                          <h4 className='tc-thin-bdr-right'>QTY</h4>
                          <h4 className='tc-thin-bdr-right'>$$</h4>
                          <h4>%%</h4>
                      </div>
                  </div>
                  <div>
                      <div className='tc-bdr-right' style={{width: '100%'}}><h4 className='wr-h4'>Wastage from 11:00</h4></div>
                      <div className='wr-div-3 tc-thin-bdr-top tc-bdr-right'>
                          <h4 className='tc-thin-bdr-right'>QTY</h4>
                          <h4 className='tc-thin-bdr-right'>$$</h4>
                          <h4>%%</h4>
                      </div>
                  </div>
                  <div>
                    <div className='tc-bdr-right' style={{width: '100%'}}><h4 className='wr-h4'>Wastage from 15:00</h4></div>
                      <div className='wr-div-3 tc-thin-bdr-top'>
                          <h4 className='tc-thin-bdr-right'>QTY</h4>
                          <h4 className='tc-thin-bdr-right'>$$</h4>
                          <h4>%%</h4>
                      </div>
                  </div>
              </section>
      );
  }
}

export default WastageReport;
