import React, {useState, useEffect} from "react";
export const MAX_VALUE = 99;

const QuantityInput = (props) => {
    // props:
    const { placeholder, setData, getData} = props

    const [quantity, setQuantity] = useState(getData(props.indexes).quantity);
    const [indexes, setIndexes] = useState(props.indexes);
    const [errorStatus, setErrorStatus] = useState(props.checkRed(props.indexes));
    // as soon as props changes, quntity and indexes also change
    useEffect (()=> {
        setQuantity(getData(props.indexes).quantity);
        setIndexes(props.indexes);
        setErrorStatus(props.checkRed(props.indexes));
        //console.log(getID(props.indexes));
    }, [props]);

    // + or - icon click
    const handleIconClick = ( _mode ) => {
        if(!(_mode === "plus" || _mode === "minus")) return; 
        
        // increment/ decrement
        let val = quantity;
        if(val < MAX_VALUE && _mode === "plus") val++;
        if(val > 0 && _mode === "minus") val--;
         
        setQuantity(val);
        setData(val, indexes); 
        setErrorStatus(props.checkRed(indexes));
    }

    // change the quantity = > call the handler
    const handleOnChange = (e) => {
        let val = (e.target.value === "") ? 0 : parseInt(e.target.value);
        //
        if (val > MAX_VALUE) val = MAX_VALUE;
        if ( val < 0 ) val = 0;

        setQuantity(val);
        setData(val, indexes); 
        setErrorStatus(props.checkRed(indexes));  
    }

    // handle key down event
    const handleKeyDown = (event)  => {

        const k = event.key;
        

        // delete or backspace
        if(k == "Backspace" || k == "Delete" || k == "ArrowLeft" || k == "ArrowRight") return;

        // numeric check
        var regex = /[0-9]|\./;
        if( !regex.test(k) ) {
            event.preventDefault();
        } 

        // 2 digits max
        const val = (event.target.value === "") ? 0 : parseInt(event.target.value);
        if( val.length >= 2 ) event.preventDefault();
        //
        setData(val, indexes);
    }

    //
    return (
        <div className="product-obj">
            <img className="small-icon" src="./media/minus.svg" onClick={ () => handleIconClick("minus") } alt="+"/>
            <input type="text" 

                id={ "qty_input_" + props.indexes.product_id }
                className={"qty_input " + errorStatus}
                placeholder={ !placeholder ? 0 : placeholder } 
                size="4" 
                value={ quantity > 0 ? quantity : "" }

                onChange={(event) => handleOnChange(event)}
                onKeyDown={(event) => handleKeyDown(event)}
            />
            <img className="small-icon" src="./media/plus.svg" onClick={ () => handleIconClick("plus") } alt="-"/>
        </div>
    );
}

export default QuantityInput;