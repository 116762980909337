import React from "react";
import { recommendations, products_obj } from "./data"
import TempCheck from "./TempCheck"
import {getDateFormatString, getDayIndex} from "./dateTime"

const BakingRsPrint = (props) => {

    // console.log(recommendations);
    const date1 = new Date(props.date1);
    const date2 = new Date(props.date2); 
    const MAX_SESSION_TYPE = 4;
    const HTML = [];
    let current_date;

    let day = date1;
    // select each day
    while (day <= date2) {
        current_date = getDateFormatString(day);
        // select all three sessions: 6am, 11am and 3pm
        for (let session_type_id = 1; session_type_id < MAX_SESSION_TYPE; session_type_id ++) {
            const data_array = getDataArray(current_date, session_type_id);
            HTML.push(<TempCheck
                date={current_date}
                time={props.time}
                session_id = {-1}
                session_type_id={session_type_id}
                data_array={data_array}
            />);
            // console.log(data_array)
        }
        // next day
        day.setDate(day.getDate() + 1)
    }
 
    function getDataArray(date, session_type_id){
        const data_array = [];
        const day_index = getDayIndex(date)

        for (const key in recommendations) {
            const r = recommendations[key]

            data_array.push({
                product_id: key,
                name: products_obj[key].name,
                category_id: products_obj[key].category_id,
                baking_program_id: products_obj[key].baking_program_id,
                baked_QTY : r.rs["D" + day_index].quantity[session_type_id-1],
                wastage_QTY: 0,
                r_QTY: r.rs["D" + day_index].quantity[session_type_id-1] // recommendations
            });
        }

        data_array.sort((a, b) => {return a.category_id - b.category_id});
        return data_array;
    }

    // HTML
    return (
        // HEADER
        <div className = "r-report temp-check">
            {/* BUTTONS BACK AND PRINT */}
            <div className='print-button-container noprint'>
                <button className='empty-black-button' onClick={(e) => { window.history.back() } }><div>{"<< Back"}</div></button>
                {/* <button className='empty-green-button' onClick={(e) => { sign(); } }><div>Sign</div></button> */}
                <button className='green-button' onClick={(e) => { window.print(); } }><div>Print</div></button>
            </div>

            {HTML}

        </div>
    );
}

export default BakingRsPrint;