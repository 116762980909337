import React, { useState, useEffect } from "react";
import { products_obj } from "./data";

const ProductThumbnail= (props) => {

    const [operation_type_id, setOperation_type_id] = useState(props.operation_type_id);

    useEffect(() => {
        setOperation_type_id(props.operation_type_id);
    }, [props]);

    const product_id = props.product_id;
    let name = '';
    let photo_link = "";

    if (Object.values(products_obj).length >= 0 && product_id >=0) {
        const pr = products_obj[product_id];

        name = pr.short_name;
        if (pr.photo) 
            photo_link = "./media/" + pr.photo;
        else 
            photo_link = "./media/pie-logo-4.png";
    }

    const onClick = () => {
        let val = props.getData({ product_id: props.product_id, operation_type_id: operation_type_id }).quantity;
        
        if(val < 99) val++;

        try {
            // assign new data
            props.setData(val, {product_id: product_id, operation_type_id: operation_type_id}); 
            // set the flag that data is changed
            props.toggleFlag_data_change();
            
        } catch {}
    }


    return (
        <div className="product-thumbnail" onClick={ () => onClick() }>
            <img src={ photo_link } />
            <div className="ochre-title">{name}</div>
        </div>
    );
}

export default ProductThumbnail;