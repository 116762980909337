import React from "react";

const Message = (props) => {
    // props:
    // title
    // message
    // button_1
    // button_2
    // button_3
    // button_2
    // button_1_onClick
    // button_2_onClick
    // button_3_onClick
    // cancel_onClick

    // button 1 onClick handler
    const button_1_onClick = (event) => {
        event.preventDefault()
        props.button_1_onClick();
    }

    // button 2 onClick handler
    const button_2_onClick = (event) => {
        event.preventDefault()
        props.button_2_onClick();
    }

    // button 3 onClick handler
    const button_3_onClick = (event) => {
        event.preventDefault()
        props.button_3_onClick();
    }

    // how to place buttons
    const getStyle = () => {
        let counter = 0;
        if (props.button_1 && props.button_1 != "") counter++;
        if (props.button_2 && props.button_2 != "") counter++;
        if (props.button_3 && props.button_3 != "") counter++;
        //
        if (counter <= 1 ) return "grid-1"
        if (counter === 2 ) return "grid-2"
        if (counter === 3 ) return "grid-3"
    }

    return (
        <div className="display-message">
            <div className="message-screen"></div>

            <form className="message-screen-form">
                    <img className="cancel-icon" src='./media/cancel.png' onClick={props.cancel_onClick}/>
                    <h2>{props.title}</h2>
                    <p className="italic">{props.message}</p>
                    <div className={ getStyle() + " gap-10"}>
                        {(props.button_1 && props.button_1 != "") ? <button className="empty-black-button" onClick={(e) => button_1_onClick(e)}><div>{props.button_1}</div></button> : ""}
                        {(props.button_2 && props.button_2 != "") ? <button className="empty-black-button" onClick={(e) => button_2_onClick(e)}><div>{props.button_2}</div></button> : ""}
                        {(props.button_3 && props.button_3 != "") ? <button className="green-button"       onClick={(e) => button_3_onClick(e)}><div>{props.button_3}</div></button> : ""}
                        
                        {/* <button className="green-button"       onClick={(e) => button_2_onClick(e)}>{props.button_2}</button> */}
                    </div>
            </form>
    </div>
    );
} 
export default Message;