import React from "react";
import { useState, useEffect } from "react";
import {getCurrentDate, getDayIndex} from './dateTime.js';
import { fetchSessions } from './data.js';
import SessionButtonsObj from './SessionButtonsObj';
import SquareButton from "./SquareButton.js";
// slyles inside reports.css



const PreviousDays = (props) => {
    const [date, setDate] = useState(props.date ? props.date : getCurrentDate());
    const [sessions, setSessions] = useState([]);

    useEffect (() => {
        fetchSessions(date).then((response) => {setSessions(response);})
    }
    , [date]);

    // when the date is selected
    const onDateChange = async (event) => {
        const _date = event.target.value;
        if (_date.trim() === "") return;
        setDate(_date);
        props.changeAppState({date: _date});
        const response = await fetchSessions(_date);
        setSessions(response);
    }

    // print baking Rs for current date
    const print_baking_Rs = (event) => {
        // move to bakingRs report
        props.loadPage("Baking_Recommntations_Print", 
            {
                date1: date,
                date2: date,
                day_index: getDayIndex(date)
            }
        );
    }

    return (
        <div className="pd-panel">
            <h2 className='italic light white'>Archive: prev.days sessions</h2>
            <form className="">
                <div>
                    <fieldset className="r-period">
                        <legend>Choose date</legend>
                        <input type="date" id="date" name="date" defaultValue={date} onChange={(e) => onDateChange(e)} />
                    </fieldset>
                </div>
                {/* <button className='empty-white-button'  onClick={(e) => searchForSessions(e) }>Search for sessions</button> */}
            </form>

            <SessionButtonsObj 
                date = {date}
                sessions = { sessions }
                page_name = "PreviousDays"
                loadPage={ props.loadPage }/>

            {/* Button to print the t check */}
            <div className='s-bottom-section three-buttons-in-a-row'>
                <SquareButton 
                        key={'obtn'+ "Print-Rs"} 
                        title={ `Print R's` }  
                        button_type={"Print-baking-Rs"}
                        onClick={() => print_baking_Rs()}
                />
            </div>
        </div>
    );
}

export default PreviousDays;