import React from 'react';
import { fetchBWOperations_sales_report, products_obj, categories } from './data.js';
import { getReportFormatDate } from './dateTime.js';
import '../css/temp-check.css';

class SalesReport extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          date1: props.date1, 
          date2: props.date2,
          number_of_days: 1,
          data: []
        }
    
        this.fetchData = this.fetchData.bind(this);
    };

    componentDidMount() {
        this.fetchData(this.state.date1, this.state.date2)
    }

    // Table body 
    fetchData(dateFrom, dateTo) { 
        fetchBWOperations_sales_report(dateFrom, dateTo).then ( response => {
        
            // array of products
            let data = [];
            
            Object.values(products_obj).forEach(pr => {
                data.push({
                    category_id: pr.category_id,
                    category_name: categories[pr.category_id].name,
                    product_id: pr.product_id,
                    product_name: pr.name,
                    
                    price: pr.purchase_price,
                    // baked, sales and wastage
                    b:0, s:0, w:0,
                    days: 1
                });
                
            });
            data.sort((a, b) => a.category_id - b.category_id );

            // distribute response
            //
            const number_of_days = response[0][0].number_of_days;
            response[1].forEach(r => {   
                data.forEach(d => {
                    if(d.product_id === r.product_id) {
                        // baked
                        d.days = r.days;
                        d.b = parseInt(r.baked);
                        d.w = parseInt(r.wastage);
                        d.s = d.b - d.w;
                    }
                });
            });

            //console.log(data);

            this.setState({
                data: data,
                number_of_days: number_of_days
            });
        });
    }

    render() {
        let period = "";

        // display period or day
        if(this.state.date1 === this.state.date2) 
            period = <h3><span className="light">{"date: "}</span> {getReportFormatDate(this.state.date1)} </h3>
        else 
            period = <h3><span className="light">{"period: from "}</span> { getReportFormatDate(this.state.date1) } <span className="light">{" to "}</span>{ getReportFormatDate(this.state.date2) }</h3>
    
        return (
            <div className='r-report sr-sales-report '>
                <div className='print-button-container noprint'>
                    <button className='empty-black-button' onClick={(e) => { window.history.back() } }><div>{"<< Back"}</div></button>
                    <button className='empty-green-button' onClick={(e) => { window.print()} }><div>Print</div></button>
                </div>
                {/* HEADER */}
                <div className="wr-header"><h3>Sales report</h3>{period}</div>
                    {/* TABLE */}
                    { this.tableHeader() }
                    { this.tableBodyDaily() }
                    
            </div>
        );
    };

    // render table body
    tableBodyDaily() {
        const html = [];
        let category_id = -1;
        let category_name = "";
        let days = this.state.number_of_days > 0 ? this.state.number_of_days : 1;
        let paroduct_index = 1;

        // category total
        let w = 0; let b = 0; let s = 0; 
        let b_cost = 0; let s_cost = 0; let w_cost = 0; 

        // table total
        let wt = 0; let bt = 0; let st = 0; 
        let b_cost_t = 0; let s_cost_t = 0; let w_cost_t = 0; 

        if (!this.state.data || this.state.data.length === 0) return "No data";
        //
        this.state.data.forEach((d) => {
            // if (d.days > 0) days = d.days; 
            //
            if (category_id === -1 ) {
                category_id = d.category_id;
                category_name = d.category_name;
            }

            // display the category
            if (category_id !== d.category_id) {
                // display category section
                const c = {
                  category_id: category_id,
                  name: category_name, 
                  w: w, b: b, s: s, 
                  b_cost: b_cost,
                  s_cost: s_cost,
                  w_cost: w_cost,
                  days: days
                }
            
                // render category
                //console.log(c);
                html.push(this.renderCategory(c));

                // calculate table totals
                wt+= w; bt+= b; st+= s; 
                b_cost_t+= b_cost;
                s_cost_t+= s_cost;
                w_cost_t+= w_cost;

                // reset totals
                w = b = s = 0; 
                b_cost = s_cost = w_cost = 0;
   
                category_id = d.category_id;
                category_name = d.category_name;
            }
            html.push(this.renderProduct(d, paroduct_index));
            paroduct_index ++;

            //  category total
            b+= d.b; w+= d.w; s+= d.s; 
            b_cost+= d.b * d.price;     
            w_cost+= d.w * d.price; 
            s_cost+= d.s * d.price; 
        });

        // display last category
        const c = {
            category_id: category_id,
            name: category_name, 
            w: w, b: b, s: s,
            b_cost: b_cost,
            s_cost: s_cost,
            w_cost: w_cost,
            days: days
          }
      
        // render category
        html.push(this.renderCategory(c));
        // calculate table totals
        wt+= w; bt+= b; st+= s;
        b_cost_t+= b_cost;
        s_cost_t+= s_cost;
        w_cost_t+= w_cost;

        // display table totals
        const t = {
            category_id: "*",
            name: "TOTAL", 
            b: bt, w: wt, s: st,
            b_cost: b_cost_t,
            s_cost: s_cost_t,
            w_cost: w_cost_t,
            days: days
          }
          html.push(this.renderCategory(t,"TOTAL"));

        return (<div key={"wr_tbl"} className=''>{html}</div>);
    }

    // Table product row
    renderProduct(d, index) {
        
        const number_of_days = this.state.number_of_days > 0 ? this.state.number_of_days : 1;
    
        return (
            <div key={"wr_pr" + index} className='tc-100 sr-div-5 tc-bdr-right tc-bdr-left'>
                <p className='tc-bdr-right tc-thin-bdr-bottom'>{ index }</p>
                <p className='tc-bdr-right tc-thin-bdr-bottom ta-left'>{ d.product_name }</p>
                {/* BAKED */}
                <div className='sr-equal-div-4 tc-thin-bdr-bottom tc-bdr-right'>
                    <p className='tc-thin-bdr-right'>{ d.b }</p>
                    <p className='tc-thin-bdr-right'>{(d.b > 0 ) ? (d.b * d.price).toFixed(2) : ""}</p>
                    <p className='tc-thin-bdr-right green'>{(d.b > 0 ) ? (d.b / number_of_days).toFixed(1) : ""}</p>
                    <p className="green">{(d.b > 0 ) ? (d.b / number_of_days * d.price).toFixed(2) : ""}</p>

                </div>
                {/* SALES */}
                <div className='sr-equal-div-4 tc-thin-bdr-bottom tc-bdr-right'>
                    <p className='tc-thin-bdr-right'>{d.s}</p>
                    <p className='tc-thin-bdr-right'>{(d.s > 0 ) ? (d.s * d.price).toFixed(2) : ""}</p>
                    <p className='tc-thin-bdr-right green'>{(d.s > 0 ) ? (d.s / number_of_days).toFixed(1) : ""}</p>
                    <p className="green">{(d.s > 0 ) ? (d.s / number_of_days * d.price).toFixed(2) : ""}</p>

                </div>
                
                {/* WASTAGE */}
                <div className='sr-equal-div-5 tc-bdr-right tc-thin-bdr-bottom'>
                    <p className='tc-thin-bdr-right'>{ d.w }</p>
                    <p className='tc-thin-bdr-right'>{(d.w > 0 ) ? (d.w * d.price).toFixed(2) : ""}</p>
                    <p className='tc-thin-bdr-right bg-light-grey'>{ (d.w === 0) ? "" : Math.round( d.w / d.b * 100) + "%" }</p>
                    <p className='tc-thin-bdr-right green'>{ (d.w > 0 ) ? (d.w / number_of_days).toFixed(1) : ""}</p>
                    <p className='green' >{(d.w > 0 ) ? (d.w / number_of_days * d.price).toFixed(2) : ""}</p>
                </div>
            </div>
        );
    }

    renderCategory(c, t = '') {
        const number_of_days = this.state.number_of_days > 0 ? this.state.number_of_days : 1;
        const element = [];

        element.push(
              <div key={"wr_ct" + c.category_id} className={`tc-100 tc-bdr-right tc-bdr-left sr-div-5 bg-light-grey bold  tc-bdr-bottom`}>
                    <p className='tc-bdr-right '></p>
                    <p className='tc-bdr-right ta-left'>{ c.name }</p>
                    {/* BAKED */}
                    <div className='sr-equal-div-4 tc-bdr-right'>
                        <p className='tc-thin-bdr-right'>{c.b}</p>
                        <p className='tc-thin-bdr-right'>{(c.b_cost).toFixed(2)}</p>
                        <p className='tc-thin-bdr-right green'>{(c.b > 0 ) ? (c.b / c.days).toFixed(1) : ""}</p>
                        <p className='green'>{(c.b_cost / c.days).toFixed(2)}</p>
                    </div>
                    {/* SALES */}
                    <div className='sr-equal-div-4 tc-bdr-right'>
                        <p className='tc-thin-bdr-right'>{c.s}</p>
                        <p className='tc-thin-bdr-right'>{(c.s_cost).toFixed(2)}</p>
                        <p className='tc-thin-bdr-right green'>{(c.s > 0 ) ? (c.s / number_of_days).toFixed(1) : ""}</p>
                        <p className='green'>{(c.s_cost / c.days).toFixed(2)}</p>
                    </div>
                  
                    {/* WASTAGE*/}
                    <div className='sr-equal-div-5'>
                            <p className='tc-thin-bdr-right'>{c.w}</p>
                            <p className='tc-thin-bdr-right'>{(c.w_cost).toFixed(2)}</p>
                            <p className='tc-thin-bdr-right'>{ c.b===0 ? "" : Math.round(c.w / c.b * 100) + "%" }</p>
                            <p className='tc-thin-bdr-right green'>{(c.w > 0 ) ? (c.w / number_of_days).toFixed(1) : ""}</p>
                            <p className='green'>{(c.w_cost / c.days).toFixed(2)}</p>
                    </div>
                </div>);

              {/* EMPTY LINE */}
              if (t !== "TOTAL")
                element.push(
                    <div key={"wr_cte" + c.category_id} className='wc-100 tc-bdr-right tc-bdr-left sr-div-5 tc-thin-bdr-bottom'>
                        <p className='tc-bdr-right'>#</p>
                        <p className='tc-bdr-right ta-left'></p>
                        {/* BAKED */}
                            <div className='sr-equal-div-4 tc-bdr-right'>
                                <p className='tc-thin-bdr-right'></p>
                                <p className='tc-thin-bdr-right'></p>
                                <p className='tc-thin-bdr-right'></p>
                                <p ></p>
                            </div>
                        {/* SALES */}
                            <div className='sr-equal-div-4 tc-bdr-right'>
                                <p className='tc-thin-bdr-right'></p>
                                <p className='tc-thin-bdr-right'></p>
                                <p className='tc-thin-bdr-right'></p>
                                <p ></p>
                            </div>
                        
                        {/* WASTAGE*/}
                        <div className='sr-equal-div-5'>
                                <p className='tc-thin-bdr-right'></p>
                                <p className='tc-thin-bdr-right'></p>
                                <p className='tc-thin-bdr-right bg-light-grey'></p>
                                <p className='tc-thin-bdr-right'></p>
                                <p ></p>
                        </div>
                    </div> 
                    
                    );  
        return element;
    }
    // render header
    tableHeader() {
        return(
            <section className='tc-100 sr-div-5 tc-bdr-top tc-bdr-left tc-bdr-right tc-bdr-bottom bg-light-grey'>
                <h4 className='tc-bdr-right wr-h4'>#</h4>
                <h4 className='tc-bdr-right wr-h4'>Products</h4>
                {/* BAKED */}
                <div>
                    <div className='tc-bdr-right' style={{width: '100%'}}><h4 className='wr-h4'>BAKED</h4></div>
                    <div className='sr-equal-div-4 tc-thin-bdr-top tc-bdr-right'>
                        <h4 className='tc-thin-bdr-right'>QTY</h4>
                        <h4 className='tc-thin-bdr-right'>$$</h4>
                        <h4 className='tc-thin-bdr-right small green'>Avg/day</h4>
                        <h4 className='green'>$$</h4>
                    </div>
                </div>
                {/* SALES */}
                <div>
                    <div className='tc-bdr-right' style={{width: '100%'}}><h4 className='wr-h4'>SALES</h4></div>
                    <div className='sr-equal-div-4 tc-thin-bdr-top tc-bdr-right'>
                        <h4 className='tc-thin-bdr-right'>QTY</h4>
                        <h4 className='tc-thin-bdr-right'>$$</h4>
                        <h4 className='tc-thin-bdr-right small green'>Avg/day</h4>
                        <h4 className='green'>$$</h4>
                    </div>
                </div>
                {/* WASTAGE */}
                <div>
                    <div className='' style={{width: '100%'}}><h4 className='wr-h4'>WASTAGE</h4></div>
                    <div className='sr-equal-div-5 tc-thin-bdr-top'>
                        <h4 className='tc-thin-bdr-right'>QTY</h4>
                        <h4 className='tc-thin-bdr-right'>$$</h4>
                        <h4 className='tc-thin-bdr-right'>%%</h4>
                        <h4 className='tc-thin-bdr-right small green'>Avg/day</h4>
                        <h4 className='green'>$$</h4>
                    </div>
                </div>

            </section>

        );
    }

}

export default SalesReport;