import React, {useState, useEffect} from "react";
import { categories, baking_programs, providers } from './data.js';
import { getPicturePath } from './dateTime.js';

import SquareButton from './SquareButton';


const ProductEditor = (props) => {
    const [is_active, setIs_active] = useState(props.is_active);
    const [product_id, setProduct_id] = useState(props.product.product_id);
    const [name, setName] = useState(props.product.name);
    const [short_name, setShort_name] = useState(props.product.short_name);
    const [category_id, setCategory_id] = useState(props.product.category_id);
    const [baking_program_id, setBaking_program_id] = useState(props.product.baking_program_id);
    const [provider_id, setProvider_id] = useState(props.product.provider_id);
    const [shelf_life_info, setShelf_life_info] = useState(props.product.shelf_life_info);
    const [opt, setOpt] = useState((props.product.opt === "OPT") ? true: false);
    const [archive, setArchive] = useState((props.product.archive === 1) ? true : false);
    const qip = props.product.qnt_in_package;
    const [qnt_in_package, setQnt_in_package] = useState(qip === 0 || qip === null ? "" : qip);
    const [photo, setPhoto] = useState(props.product.photo);
    const [description, setDescription] = useState(props.product.description);
    const pp = props.product.purchase_price;
    const [purchase_price, setPurchase_price] = useState(pp === 0 || pp === null ? "" : pp);
    const sp = props.product.selling_price;
    const [selling_price, setSelling_price] = useState(sp === 0 || sp === null ? "" : sp);

        // as soon as props changes, set of indexes is also changes
        useEffect(() => {
            setIs_active(props.is_active);
            setProduct_id(props.product.product_id);
            setName(props.product.name);
            setShort_name(props.product.short_name);
            setCategory_id(props.product.category_id);
            setBaking_program_id(props.product.baking_program_id);
            setProvider_id(props.product.provider_id);
            setShelf_life_info(props.product.shelf_life_info);
            setOpt((props.product.opt === "OPT") ? true: false);
            setArchive((props.product.archive === 1) ? true : false);
            const qip = props.product.qnt_in_package;
            setQnt_in_package(qip === 0 || qip === null ? "" : qip);
            setPhoto(props.product.photo);
            setDescription(props.product.description);
            const pp = props.product.purchase_price;
            setPurchase_price(pp === 0 || pp === null ? "" : pp);
            const sp = props.product.selling_price;
            setSelling_price(sp === 0 || sp === null ? "" : sp);

        },[props]);

    // if any of the fields is changed
    const handleChange = (e, attr_name) => {

        if(attr_name === "name") setName(e.target.value);
        if(attr_name === "short_name") setShort_name(e.target.value);
        if(attr_name === "category_id") setCategory_id(e.target.value);
        if(attr_name === "baking_program_id") setBaking_program_id(e.target.value);
        if(attr_name === "provider_id") setProvider_id(e.target.value);
        if(attr_name === "shelf_life_info") setShelf_life_info(e.target.value);
        if(attr_name === "OPT") setOpt(e.target.checked);
        if(attr_name === "archive") setArchive(e.target.checked);
        if(attr_name === "QTY_in_pack") setQnt_in_package(e.target.value);
        if(attr_name === "description") setDescription( e.target.value );
        if(attr_name === "purchase_price") setPurchase_price(e.target.value);
        if(attr_name === "selling_price") setSelling_price(e.target.value);
    }

    // key Down on numeric field
    const handleNumberKeyDown = (event) => {
        const k = event.key;
        // delete or backspace
        if(k === "Backspace" || k === "Delete" || k === "ArrowLeft" || k === "ArrowRight" || k === ".") return;

        // numeric check
        var regex = /[0-9]|\./;
        if( !regex.test(k) ) {
            event.preventDefault();
        }   
        return;
    }

    // download picture
    const handleDownloadClick = (event) => {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = "image/jpeg, image/jpg";
        input.capture = "environment";
        input.onchange = _ => {
            setPhoto (input.files[0].name);
        };
        input.click();

        event.preventDefault();    
    }

    // discard changes and exit the editor
    const cancel = (event) => {
        //event.preventDefault();
        props.cancel();
    }

    // save changes to database and exit the editor
    const submit = (event) => {
        //event.preventDefault();

        // save data
        const product = {
            product_id: product_id,
            name: name.toUpperCase().trim(),
            short_name: short_name.trim(), 
            category_id: category_id,
            baking_program_id: baking_program_id,
            provider_id: provider_id, 
            shelf_life_info: ( shelf_life_info!=="" ? shelf_life_info.trim() : ""),
            opt: (opt) ? "OPT": "",
            archive: (archive) ? 1 : 0,
            qnt_in_package: (qnt_in_package === "" || qnt_in_package === "0") ? null : parseInt(qnt_in_package),
            photo: photo,
            description: (description!== "" ? description.trim() : ""),
            purchase_price: (purchase_price === "" || purchase_price === "0") ? null :  parseFloat(purchase_price),
            selling_price:  (selling_price === ""  || selling_price === "0") ? null :  parseFloat(selling_price)
        }
        props.saveProduct(product);
    }

    return (
        <div className = {"pe-product-editor "+ (is_active? "" : "disabled")}>
            {/* <div className = "pe-product-editor disabled"> */}
            <form>
                {/* NAME */}
                <div className="pe-section-two">
                    <label className="pe-label" htmlFor="name" >Product name:</label>
                    <input className="pe-input" 
                        type="text" 
                        id="name" 
                        name="name" 
                        placeholder="PIE STD YUMMY" 
                        value={name}
                        onChange={(e) => handleChange(e, "name")} 
                        style={{marginBottom: "10px", marginTop: "10px"}}/>                      
                </div>
                <div className="pe-section-two-revert">
                    <div className="pe-section-two"> 

                        {/* SHORT NAME */}
                        <label className="pe-label" htmlFor="short_name">Short name:</label>
                        <input className="pe-input" 
                            type="text" 
                            id="short_name" 
                            name="short_name" 
                            placeholder="Yummy pie" 
                            value={short_name} 
                            onChange={(e) => handleChange(e, "short_name")} 
                            />
                        
                        {/* CATEGORY  */}
                        <label className="pe-label" htmlFor="category">Category:</label>
                        <select className="pe-input" 
                                id="category" 
                                value={category_id}  
                                onChange={(e) => handleChange(e, "category_id")} 
                                name="category" 
                                >
                            {categories.map(c => <option key={c.category_id} value={c.category_id} >{c.name}</option> )}
                        </select>

                        {/* BAKING PROGRAM */}
                        <label className="pe-label" htmlFor="baking_program">Baking program:</label>
                        <select className="pe-input" 
                                id="baking_program" 
                                name="baking_program" 
                                value={baking_program_id} 
                                onChange={(e) => handleChange(e, "baking_program_id")}
                                placeholder="Select baking program" 
                                >
                            {baking_programs.map(c => <option key={c.program_id} value={c.program_id} >{c.name}</option> )}
                        </select>

                        {/* PROVIDER */}
                        <label className="pe-label" htmlFor="provider">Provider:</label>
                        <select className="pe-input" 
                                id="provider" 
                                name="provider" 
                                value={provider_id} 
                                onChange={(e) => handleChange(e, "provider_id")}
                                placeholder="Select provider" 
                                >
                            {providers.map(p => <option key={p.provider_id} value={p.provider_id}>{p.name}</option> )}
                        </select>

                        {/* SHELF LIFE */}
                        <label className="pe-label" htmlFor="shelf_life">Shelf life:</label>
                        <div> 
                            <input className="pe-input" 
                                    type="text" 
                                    id="shelf_life" 
                                    value={shelf_life_info} 
                                    onChange={(e) => handleChange(e, "shelf_life_info")}
                                    name="shelf_life" 
                                    placeholder="6-7 hours" 
                                    size="8"/>
                            
                            {/* OPT */}
                            <input className="pe-input" 
                                type="checkbox" id="product_OPT" 
                                name="OPT" 
                                checked={opt} 
                                onChange={(e) => handleChange(e, "OPT")}
                                style={{marginLeft: "10px"}}/>
                            <label className="pe-label" htmlFor="OPT">OPT</label>
                            {/* archive */}
                            <input className="pe-input" 
                                    type="checkbox" id="archive" 
                                    name="archive" 
                                    checked={archive} 
                                    onChange={(e) => handleChange(e, "archive")}
                                    style={{marginLeft: "10px"}}/>
                            <label className="pe-label" htmlFor="archive">Archive</label>
                        </div>

                    </div>

                    {/* PHOTO */}
                    <div className="pe-photo-container">
                        <div className="product-thumbnail">
                            <img src={ getPicturePath(photo) } alt="product"/>
                        </div>
                        <button className="empty-green-button" style={{marginTop: "10px", width: "fit-content"}} onClick={(e) => handleDownloadClick(e)} ><div>Download photo</div></button>
                    </div>
                </div>
                
                <div className="pe-section-two">
                    {/* DESCRIPTION */}
                    <label className="pe-label" htmlFor="desc" style={{marginTop: "0"}}>Description:</label>
                    <textarea className="pe-input" 
                        type="text" 
                        value={description} 
                        onChange={(e) => handleChange(e, "description")}
                        rows="8" cols = "40" 
                        id="desc" 
                        name="desc" 
                        placeholder="Description" 
                        style={{ marginTop: "10px", marginBottom: "10px"}}/>                      
                </div>

                <div className="pe-section-two-revert-100px">
                    <div className="pe-section-two">
                        {/* QTY IN PACK */}
                        <label className="pe-label" htmlFor="QTY_in_pack">QTY in package:</label>
                        <input className="pe-input"
                            type="text" 
                            id="QTY_in_pack" 
                            value={qnt_in_package} 
                            onChange={(e) => handleChange(e, "QTY_in_pack")}
                            name="QTY_in_pack" 
                            onKeyDown={(e) => {handleNumberKeyDown(e)}} 
                            placeholder="10" 
                            style={{width: "50px"}}/>
                            
                        {/* PURCHASE PRICE */}
                        <label className="pe-label" htmlFor="purchase_price" style={{marginRight: "10px"}}>Purchase price p/one ($):</label>
                        <input className="pe-input" 
                            type="text" id="purchase_price" 
                            value={purchase_price} 
                            onChange={(e) => handleChange(e, "purchase_price")}
                            onKeyDown={(e) => {handleNumberKeyDown(e)}} 
                            name="purchase_price" 
                            placeholder="4.00" 
                            style={{width: "50px"}}/>

                        {/* SELLING PRICE */}
                        <label className="pe-label" htmlFor="selling_price" style={{marginRight: "10px"}}>Selling price p/one ($):</label>
                        <input className="pe-input" 
                            type="text" 
                            id="selling_price" 
                            value={selling_price} 
                            onChange={(e) => handleChange(e, "selling_price")}
                            onKeyDown={(e) => {handleNumberKeyDown(e)}}  
                            name="selling_price" 
                            placeholder="4.50" 
                            style={{width: "50px"}}/>
                    </div>
                    <div>
                        <div className="pe-buttons">
                            <SquareButton 
                                
                                title={ "Submit changes" } 
                                product_id={product_id } 
                                button_type={"Submit_products"}
                                onClick={(e) => submit(e)}
                            />

                            <SquareButton 
                                title={ "Cancel" } 
                                product_id={ product_id } 
                                button_type={"Cancel_products"}
                                onClick={(e) => cancel(e)}
                            />
                        </div>
                    </div>
                </div>
                
            </form>
            
        </div>
    );

}

export default ProductEditor;