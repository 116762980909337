import React from 'react';
import { useEffect, useState } from 'react';
import ProductAvg from './ProductAvg';
import QuantityInput from './QuantityInput';
import { getPicturePath } from './dateTime.js';

const Product = (props) => {
    const { p, mode } = props;
    const [indexes, setIndexes] = useState(props.indexes);

    // as soon as props changes, set of indexes is also changes
    useEffect(() => {
        setIndexes(props.indexes);
    },[props]);

    //Norton
    // 74512004
    // Please, take a note of your refund confirmation number RMA1000000000012422873. 
    // Marina75%

    let f_img = false;
    let f_qty = false;
    let f_avg = false;
    let f_edit = false;
    let style_class = "";
    let photo_link = "";

    switch (mode) {
        case 'RECOMMENDATIONS':
            f_img = true;
            f_qty = true;
            f_avg = true;
            // style_class = "product-obj-40-60";
            style_class = 'product-obj-layout po-40-60'
            break;
        case 'OPERATIONS':
            f_qty = true;
            style_class = "product-obj-layout po-60-40";
            break;
        case 'PRODUCT_EDITOR':
            f_img = true;
            f_edit = true;
            style_class = "product-obj-layout po-1fr-40px";
            break;
    }

    // photo
    if (f_img) photo_link = getPicturePath(p.photo)

    return (
        <div className={style_class}> 
            { f_img ? <img className="product-img" src={ photo_link } onClick = {() => props.imageClick( indexes )}/> : ""}

            <p>{p.name}</p>
            <div className='grid-2'>
                {f_avg ? <ProductAvg
                indexes = { indexes }
                getData={ props.getData }
                /> 
                : ""}

                {f_qty ? <QuantityInput
                    key={"qi_s" + p.product_id}

                    placeholder = {props.getRs(props.indexes)}
                    indexes = { indexes }
                    setData={ props.setData } 
                    getData={ props.getData }
                    checkRed = { props.checkRed }
                /> : ''}

                {f_edit ?  
                <img className="small-icon" 
                    src="./media/edit.svg" 
                    alt="edit product"
                    title="edit product"
                    onClick = {() => props.edit(indexes)}/>
                : ''}
            </div>

        </div>
    );
}

export default Product;