import React, {useState, useEffect} from "react";

import ProductListObj from './ProductList';
import SquareButton from "./SquareButton";
import Message from "./Message";

import { recommendations, calculateRecommendations, updateAllRecommendations, fetchRecommendations, products_obj } from './data.js';
import {getDayIndex, getCurrentDate} from './dateTime';
import { MAX_VALUE } from'./QuantityInput'


import '../css/index.css';

let new_recommendations = {};

const Recommendations = (props) => {
    // 0 = Monday... 6 = Sunday 
    // default - today
    const [day_of_week, setDay_of_week] = useState(getDayIndex());
    const [session_index, setSession_index] = useState(0);
    const [flag_data_change, setFlag_data_change] = useState(true);
    const [flag_show_message, setFlag_show_message] = useState(false);
    // 0 = 6:00, 1 = 11:00, 2 = 15:00
    
    
    // assign the data when the input field change
    //const new_recommendations = recommendations; 
    // deep copy
    useEffect(() => {
        calculateRecommendations().then((response) => {
   
            new_recommendations = JSON.parse(JSON.stringify(recommendations));
            //
            // set baked/wastage/percent to zero
            Object.values(new_recommendations).forEach(p => {
                Object.values(p.rs).forEach(r => {
                r.baked   = [0, 0, 0];
                r.wastage = [0, 0, 0];
                r.percent = [0, 0, 0];
                })
            });

            // console.log(response[0]);
            
            // response[0].filter(r => products_obj[r.product_id]).forEach((r) => {
            response[0].forEach((r) => {
                //if (! products_obj[r.product_id]) continue;
                // baked
                // console.log(r.product_id, new_recommendations[r.product_id]);
                new_recommendations[r.product_id].rs["D" + r.day_id].baked[0] = parseFloat(r.b1); // 6:00
                new_recommendations[r.product_id].rs["D" + r.day_id].baked[1] = parseFloat(r.b2); // 11:00
                new_recommendations[r.product_id].rs["D" + r.day_id].baked[2] = parseFloat(r.b3); // 15:00
        
                // wastage
                new_recommendations[r.product_id].rs["D" + r.day_id].wastage[0] = parseFloat(r.w1); // 6:00
                new_recommendations[r.product_id].rs["D" + r.day_id].wastage[1] = parseFloat(r.w2); // 11:00
                new_recommendations[r.product_id].rs["D" + r.day_id].wastage[2] = parseFloat(r.w3); // 15:00
                
                // percent
                new_recommendations[r.product_id].rs["D" + r.day_id].percent[0] = (r.b1 > 0)? r.w1/r.b1 : 0; // 6:00
                new_recommendations[r.product_id].rs["D" + r.day_id].percent[1] = (r.b2 > 0)? r.w2/r.b2 : 0; // 11:00
                new_recommendations[r.product_id].rs["D" + r.day_id].percent[2] = (r.b3 > 0)? r.w3/r.b3 : 0; // 15:00
            });

            // redraw the webpage for new data
            setFlag_data_change(!flag_data_change);

        }); 
        
    }, []);


    // assign the new_recommendation array with new data
    const assignRecommendations = (data, indexes) => {
        new_recommendations[indexes.product_id].rs["D" + indexes.day_index].quantity[indexes.calc_session_id] = data;
        //console.log(indexes, new_recommendations[indexes.product_id].rs["D" + indexes.day_index].quantity[indexes.calc_session_id]);
    }

    // increment the recommendation quantity 
    // (on icon click)
    const increment = (indexes) => {

        let val = new_recommendations[indexes.product_id].rs["D" + indexes.day_index].quantity[indexes.calc_session_id];

        if (val >= MAX_VALUE) val = MAX_VALUE; else val++;
        new_recommendations[indexes.product_id].rs["D" + indexes.day_index].quantity[indexes.calc_session_id] = val;

        // redraw the webpage for new data
        setFlag_data_change(!flag_data_change);
    }

    // get data from new_recommendations array
    const getRecommendations = (indexes) => {
        let result = {"quantity": 0};
        try {
            result = {
                quantity: new_recommendations[indexes.product_id].rs["D" + indexes.day_index].quantity[indexes.calc_session_id],
                baked: new_recommendations[indexes.product_id].rs["D" + indexes.day_index].baked[indexes.calc_session_id],
                wastage: new_recommendations[indexes.product_id].rs["D" + indexes.day_index].wastage[indexes.calc_session_id],
                percent: new_recommendations[indexes.product_id].rs["D" + indexes.day_index].percent[indexes.calc_session_id],
            };
        } catch {}

        return result;
    }

    // used in Operations
    const getRs = ( indexes ) => {return 0};

    // used in Operations
    const checkRed = (indexes) => {return ""};


    // calculate the selected day style
    const selectDay = (day_index, _day_of_week) => {
        return (day_index === _day_of_week) ? 'rs-selected-day' : '';
    }

    // calculate the selected session style
    const selectSession = (_index, _session_index) => {
        return (_index === _session_index) ? 'rs-tab-selected' : '';
    }

    // SUBMIT button 
    const save_onClick = async () => {
        let change_flag = false;
        // check changes
        const data = [];
        for (const [r_key, r] of Object.entries(new_recommendations)) {
            for (const [d_key, d] of Object.entries(r.rs)) {
                //
                change_flag = false;
                
                d.quantity.forEach((q, index) => {
                    
                    if(q !== recommendations[r_key].rs[d_key].quantity[index])
                        change_flag = true;
                });

                // prepare data for fetch
                if (change_flag) {
                    data.push({
                        recommendation_id: d.id,
                        product_id: r.product_id,
                        day_id: parseInt(d_key.substring(1)),
                        quality_session_1: d.quantity[0],
                        quality_session_2: d.quantity[1],
                        quality_session_3: d.quantity[2]
                    })
                };
            } 
        }
        

        if (data.length > 0) {

            // updateAllRecommendations(data).then ((response) =>  {
            //     console.log(response);
            //     fetchRecommendations().then (() => console.log("recommendations"));
            // });

            await updateAllRecommendations(data);
            await fetchRecommendations();
        }

    }

    // exit Rs and return to Today page
    const exit = () => {
        props.loadPage("Today", 
            {
                date: getCurrentDate(), 
                session_id: -1, 
                time: "", 
                session_type_id: -1, 
            }
        );
    }
    
    // cancel and exit button
    const exit_onCLick = () => {
        // check if any record has been changed
        let change_flag = false;
        for (const [r_key, r] of Object.entries(new_recommendations)) { 
            for (const [d_key, d] of Object.entries(r.rs)) {
                //
                d.quantity.forEach((q, index) => {
                    if(q !== recommendations[r_key].rs[d_key].quantity[index])
                        change_flag = true;
                });
            }
        }

        // if there are chenges -> open the dialog else -> exit
        if (change_flag) {
            setFlag_show_message(true);
        }
        else exit();
    }

    // cancel exit the page
    const cancel_onClick = () => {
        setFlag_show_message(false);  
    }

    // save changes when Yes pressed
    const yes_onClick = async () => {
        // setFlag_show_message(false);
        await save_onClick();  
        exit();
    }


    return (
        <div className="rs">
            <div className="white bg-green rs-header">
                <h2 className='italic light'>Recommendations</h2>
                <div className='rs-session-tab-container'>
                    <div className={`rs-session-tab ${selectSession(0, session_index)}`} onClick={() => setSession_index(0)}>6:00</div>
                    <div className={`rs-session-tab ${selectSession(1, session_index)}`} onClick={() => setSession_index(1)}>11:00</div>
                    <div className={`rs-session-tab ${selectSession(2, session_index)}`} onClick={() => setSession_index(2)}>15:00</div>
                </div>
            </div>
            <div className="rs-container">
                <div className="rs-scroll">
                    <ProductListObj 
                        product_list = { products_obj }
                        setData = { assignRecommendations }
                        getData = { getRecommendations }
                        checkRed = { checkRed }
                        getRs = { getRs }
                        imageClick = { increment }
                        mode = "RECOMMENDATIONS"
                        indexes = { {day_index: day_of_week, calc_session_id: session_index, flag_data_change:flag_data_change} }
                    />
                </div>


                <div className="rs-days-container">
                    <div className={`rs-day ${selectDay(0, day_of_week)}`} onClick={() => setDay_of_week(0)}>MONDAY</div>
                    <div className={`rs-day ${selectDay(1, day_of_week)}`} onClick={() => setDay_of_week(1)}>TUESDAY</div>
                    <div className={`rs-day ${selectDay(2, day_of_week)}`} onClick={() => setDay_of_week(2)}>WEDNESDAY</div>
                    <div className={`rs-day ${selectDay(3, day_of_week)}`} onClick={() => setDay_of_week(3)}>THURSDAY</div>
                    <div className={`rs-day ${selectDay(4, day_of_week)}`} onClick={() => setDay_of_week(4)}>FRIDAY</div>
                    <div className={`rs-day ${selectDay(5, day_of_week)}`} onClick={() => setDay_of_week(5)}>SATURDAY</div>
                    <div className={`rs-day ${selectDay(6, day_of_week)}`} onClick={() => setDay_of_week(6)}>SUNDAY</div>

                    <SquareButton 
                        key={'rbtn'+ "Submit"} 
                        title={ "Save changes" } 
                        button_type={"Submit"}
                        onClick={() => save_onClick() }
                    />

                    <SquareButton 
                        key={'rbtn'+ "Cancel"} 
                        title={ "Exit" } 
                        button_type={"Cancel"}
                        onClick={() => exit_onCLick() }
                    />
                </div>
            </div> 

            {/* MESSAGE WINDOW */}
            {flag_show_message ? 
                <Message 
                    title = "Save changes?"
                    button_1="No"
                    button_1_onClick={exit}
                    button_3="Yes"
                    button_3_onClick={yes_onClick}
                    cancel_onClick = {cancel_onClick}/>
            : ""}
        </div>
    );
}

export default Recommendations; 