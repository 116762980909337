import React from "react";

class Category extends React.Component {
    
    constructor(props) {
      super(props);

      this.state = {
        category_id: props.category_id,
        name: props.name
      }  
    }

    render() {    
        const {name, mode} = this.props; 
        let category_style = "";

        if (!mode) category_style = "category"; 
        if (mode === 'PD') category_style = "category-2"; 
        if (mode === 'RS') category_style = "category product-obj-2";

        return (
            <div className="category-container">
                <h4 className={category_style}>{ name }</h4>
            </div>
        );
      }
}



export default Category;