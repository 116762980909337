import React, { useCallback, useRef} from "react";
import '../css/signatureBox.css';

const SignatureBox = ({ cancel_onClick, submit_onClick }) => {
    
    let ctx = null;
    const size = {x:0, y:0};
    const currentPosition = {x:0, y:0};
    const color = '#0505c6'; // pen blue
    const penSize = 8;
    let isDown = false;
    let canvas = {};

    const sigCanvas = useCallback(node => {
        if(node !== null) {
            canvas = node;

            ctx = node.getContext("2d");

            ctx.strokeStyle = color;
            // ctx.fillStyle = color;
            ctx.lineWidth = penSize;
            ctx.lineJoin = ctx.lineCap = 'round';
            size.x = node.width;
            size.y = node.height;
        }
    });

    // on mouse Down event handler
    const onMouseDownHandler = (event) => {
        // console.log(event.type);
        // if (event.type === "mousedown")
        //     event.preventDefault();

        isDown = true;
        ctx.beginPath();
        const [x,y] = getTargetPosition(event);
        ctx.moveTo(x,y);

        // remember the current position
        currentPosition.x = x;
        currentPosition.y = y;
    }

    // on Mouse Up event handler
    const onMouseUpHandler = (event) => {
        // if (event.type === "mouseup")
        //     event.preventDefault();

        isDown = false;

        // draw little dot in case of click without movement
        const [x,y] = getTargetPosition(event);
        if (currentPosition.x === x && currentPosition.y === y) {
            ctx.beginPath();
            ctx.arc(x, y, 0, 0, 2 * Math.PI);
            ctx.fill()
            ctx.stroke();
        }
    }

    // on mouse move event handler
    const onMouseMoveHandler = (event) => {
        //event.preventDefault();

        if ( !isDown ) return;

        const [x,y] = getTargetPosition(event);
        ctx.lineTo(x,y);
        ctx.stroke();

        // remember the current position
        currentPosition.x = x;
        currentPosition.y = y;
    }

    // calculate x,y
    const getTargetPosition = (event) => {
        // console.log(event.type);
        if (event.type === "mousemove" || event.type === "mouseup" || event.type === "mousedown") {
            const x = event.clientX - event.target.getBoundingClientRect().x;
            const y = event.clientY - event.target.getBoundingClientRect().y;
            return [x, y];
        }
        else if (event.type === "touchmove" || event.type === "touchstart") {
            // console.log(event.targetTouches[0].force);
            const x = event.touches[0].clientX - event.target.getBoundingClientRect().x;
            const y = event.touches[0].clientY - event.target.getBoundingClientRect().y;
            return [x, y];
        }
        else if (event.type === "touchend") {
            const x = event.changedTouches[0].clientX - event.target.getBoundingClientRect().x;
            const y = event.changedTouches[0].clientY - event.target.getBoundingClientRect().y;
            return [x, y];
        }
    }

    // clean the canvas
    const clean = () => 
        ctx.clearRect(0, 0, size.x, size.y); 
    
    
    // submit button onClick handler
    const submit = () => {
        // crop all transparent pixels
        const trimmed = trim();
        const url = trimmed.toDataURL();
        submit_onClick(url);
    }

    // trim transparent pixels from the image
    const trim = () => {
        let pixels = ctx.getImageData(0, 0, canvas.width, canvas.height),
            l =  pixels.data.length,
            copy = document.createElement('canvas').getContext('2d'),
            i,
            bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
              },
              x, y;

        // check all not null pixels
        for(i=0; i<l; i+=4) {
            if (pixels.data[i + 3] !== 0) {
                x = (i / 4) % canvas.width;
                y = ~~((i / 4) / canvas.width);

                if(bound.left === null) bound.left = x;
                if(bound.right === null) bound.right = x;
                if(bound.top === null) bound.top = y;
                if(bound.bottom === null) bound.bottom = y;

                if(bound.left > x) bound.left = x;
                if(bound.right < x) bound.right = x;
                if(bound.top > y) bound.top = y;
                if(bound.bottom < y) bound.bottom = y;
            }
        }
        
        // Calculate the height and width of the content
        var trimHeight = bound.bottom - bound.top,
        trimWidth = bound.right - bound.left,
        trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

        copy.canvas.width = trimWidth;
        copy.canvas.height = trimHeight;
        copy.putImageData(trimmed, 0, 0);

        // Return trimmed canvas
        return copy.canvas;
    }
    
    // render 
    return (
        <div className="sb-signature-box">
            <div className="sb-background"></div>
            <div className="sb-container">
                <h2>Your signature or initials here:</h2>
                <img className="cancel-icon" src='./media/cancel.png' onClick={ cancel_onClick }/>
                <canvas 
                    id="sb-canvas" 
                    ref={ sigCanvas }
                    width="500" 
                    height="240"
                    // onPointerDown= {(e) => onMouseDownHandler(e)}
                    // onPointerUp= {(e) => onMouseUpHandler(e)}
                    // onPointerMove= {(e) => onMouseMoveHandler(e)}>

                    onMouseDown= {(e) => onMouseDownHandler(e)}
                    onTouchStart={(e) => onMouseDownHandler(e)}

                    onMouseUp= {(e) => onMouseUpHandler(e)}
                    onTouchEnd={(e) => onMouseUpHandler(e)}

                    onMouseMove= {(e) => onMouseMoveHandler(e)}
                    onTouchMove={(e) => onMouseMoveHandler(e)}
                    > 
                </canvas>
                <div className="three-buttons-in-a-row">
                    <button className="empty-black-button" onClick={ cancel_onClick }><div>Cancel</div></button>
                    <button className="empty-green-button" onClick={ clean }><div>Clean</div></button>
                    <button className="green-button" onClick={ submit }><div>Submit</div></button>
                </div>
            </div>
        </div>
    );
}

export default SignatureBox;