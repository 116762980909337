import React from 'react';
//import { useHistory } from "react-router-dom";

import Header from './Header';
import Sessions from './Sessions';
import Operations from './Operations';
import TempCheck from './TempCheck';
import Products from './Products';
import Reports from './Reports';
import WastageReport from './WastageReport';
import SalesReport from   './SalesReport';
import RecommendationsReport from './RecommendationsReport';
import Recommendations from './Recommendations';
import PreviousDays from './PreviousDays';
import BakingRsPrint from './BakingRsPrint'
// import Days from './Days';

import Login from './Login';
import { fetchCategories, fetchProducts, fetchSessionTypes, fetchBakingPrograms, fetchProviders, fetchRecommendations } from './data.js';
import { getCurrentDate, getDayIndex } from './dateTime.js';
import '../css/index.css';


const pushState = (obj, url) => 
  window.history.pushState(obj, '', url);


export const pushNewData = (obj) => {
  const url = new URL(window.location);
  window.history.replaceState(obj, "", url);
}

const onPopState = (handler) => 
  window.onpopstate = handler;


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      currentPage: "Login", //"Today",
      data: {
        date: getCurrentDate(), 
        session_id: -1, 
        time: "", 
        session_type_id: -1, 
        date1: null, 
        date2: null },
        day_index: -1,
        data_array: [],
        user: {
          user_name: "",
          f_today: 0,
          f_previous_days: 0,
          f_products: 0,
          f_reports: 0,
          f_recommendations: 0
        }
    };

    this.loadPage = this.loadPage.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  componentDidMount() {

      // navigation
      pushState({currentPage:'Login'}, `/`);
      // clich << nacigation event handler
      onPopState((event) => {
        this.setState ({
          currentPage:  (event.state || {}).currentPage,
          data: (event.state || {}).data
        }); 
      });
  }
  
  // change the state of the page
  changeState(new_data) {

    //console.log(new_data.day_index, new_data.day_index === undefined);
    const full_data = {
      date: (new_data.date ? new_data.date : this.state.data.date), 
      time: (new_data.time ? new_data.time : this.state.data.time), 
      session_id: (new_data.session_id ? new_data.session_id : this.state.data.session_id),
      session_type_id: (new_data.session_type_id ? new_data.session_type_id : this.state.data.session_type_id),
      date1: (new_data.date1 ? new_data.date1 : this.state.data.date1), 
      date2: (new_data.date2 ? new_data.date2 : this.state.data.date2), 
      day_index: ( new_data.day_index === undefined ? this.state.data.day_index : new_data.day_index ), 
      data_array: (new_data.data_array ? new_data.data_array : this.state.data.data_array), 
    }

    //this.setState( { data: full_data } );  
    pushNewData({currentPage: this.state.currentPage, data: full_data}, `/${this.state.currentPage}`);
  }

  // load the view
  // re-render the page
  loadPage(new_page_name, new_data) {


    const full_data = {
      date: (new_data.date ? new_data.date : this.state.data.date), 
      time: (new_data.time ? new_data.time : this.state.data.time), 
      session_id: (new_data.session_id ? new_data.session_id : this.state.data.session_id),
      session_type_id: (new_data.session_type_id >=0 ? new_data.session_type_id : this.state.data.session_type_id),
      date1: (new_data.date1 ? new_data.date1 : this.state.data.date1), 
      date2: (new_data.date2 ? new_data.date2 : this.state.data.date2), 
      day_index: (new_data.day_index === undefined  ? this.state.data.day_index : new_data.day_index ), 
      data_array: (new_data.data_array ? new_data.data_array : this.state.data.data_array), 
    }

    // browser navigation
    pushState({currentPage: new_page_name, data: full_data}, `/${new_page_name}`);
    // page construction
    this.setState(
      { currentPage: new_page_name, data: full_data });  
  }

  // login with correct password
  login(user) {
    // page constraction
    const data = {
      date: getCurrentDate(), 
      session_id: -1, 
      time: "", 
      session_type_id: -1, 
      date1: null, 
      date2: null,
      day_index: -1,
      data_array: []
    };
    
    fetchCategories().then (() => {let zzz = 1});
    fetchProducts().then (() => { fetchRecommendations() });
    fetchSessionTypes().then (() => {let zzz = 1});
    fetchBakingPrograms().then(() => {let zzz = 1});
    fetchProviders().then(() => {let zzz = 1});


    this.setState({
      //
      currentPage: "Today",
      data: data,
      user: {
      user_name: user.user_name,
      f_today: user.f_today,
      f_previous_days: user.f_previous_days,
      f_products: user.f_products,
      f_reports: user.f_reports,
      f_recommendations: user.f_recommendations
   }});

   // browser navigation
   pushState({currentPage: "Today", data: data}, `/Today`);
  }

  // login with correct password
  logout() {
    // browser navigation
    pushState({currentPage: "Login"}, `/`);

    this.setState({
      //
      currentPage: "Login",
      data: {
        date: getCurrentDate(), 
        session_id: -1, 
        time: "", 
        session_type_id: -1, 
        date1: null, 
        date2: null,
        day_index: -1,
        data_array: []
      },
      user: {
        user_name: "",
        f_today: 0,
        f_previous_days: 0,
        f_products: 0,
        f_reports: 0,
        f_recommendations: 0
   }});
  }

  render() {
    // check date
    // if( this.state.data.date != getCurrentDate() ) this.logout();

    let main_element;

    switch(this.state.currentPage ) {
      case "Login":
        main_element =  
            <div className="App">
              <Header key="hd" loadPage={this.loadPage} logout={this.logout} user={this.state.user}/>
              <Login login={this.login}/>
            </div>;
          break;

        case "Products": {
          main_element = 
              <div className="App">
                <Header key="hd" loadPage={this.loadPage} logout={this.logout} user={this.state.user}/>
                <Products loadPage={this.loadPage}/>
              </div>;
            break;
        }
        case "Today": 
          main_element = 
            <div className="App">
              <Header key="hd" loadPage={this.loadPage} logout={this.logout} user={this.state.user}/>
              <Sessions key="sss" date={this.state.data.date} loadPage={this.loadPage} />
            </div>
          break;        

        case "Operations": 
          main_element = 
            <div className="App">
              <Header key="hd" loadPage={ this.loadPage } logout={this.logout} user={this.state.user}/>
              <Operations key="os" 
                loadPage={this.loadPage} 
                date =            {this.state.data.date} 
                time =            {this.state.data.time} 
                session_id     =  {this.state.data.session_id} 
                session_type_id = {this.state.data.session_type_id}
                changeAppState = {this.changeState}/>
            </div>;
            break;          

        case "Temp_check": {
          //console.log("app.state", this.state.data.data_array);
          main_element = 
            <div className="App">
              <Header key="hd" loadPage={ this.loadPage } logout={this.logout} user={this.state.user}/>
              <TempCheck key="tc" 
                loadPage={this.loadPage} 
                date =            {this.state.data.date} 
                time =            {this.state.data.time} 
                session_id     =  {this.state.data.session_id} 
                session_type_id = {this.state.data.session_type_id}
                data_array =      {this.state.data.data_array}/>
            </div>;
            break;      
        }

        case "Reports": {
          main_element = 
            <div className="App">
              
              <Header key="hd" loadPage={this.loadPage} logout={this.logout} user={this.state.user}/>
              <Reports key="rf" 
                loadPage={this.loadPage}
                // date1 = {this.state.data.date1 ?  this.state.data.date1 : getCurrentDate(-7)}
                date1 = {this.state.data.date1 ?  this.state.data.date1 : getCurrentDate()}
                date2 = {this.state.data.date2 ? this.state.data.date2 : getCurrentDate()}
                day_index = { !(this.state.data.day_index === undefined) ?  this.state.data.day_index : getDayIndex(getCurrentDate()) }
                changeAppState = {this.changeState}/>
            </div>;
          break;         
        }

        case "Wastage_report": {
          main_element = 
            <div className="App">
              <Header key="hd" loadPage={this.loadPage} logout={this.logout} user={this.state.user}/>
              <WastageReport key="wr"  
                loadPage={this.loadPage}
                date1 = {this.state.data.date1}
                date2 = {this.state.data.date2}
                />
            </div>;
            break;      
        }

        case "Sales_report": {
          main_element = 
            <div className="App">
              <Header key="hd" loadPage={this.loadPage} logout={this.logout} user={this.state.user}/>
              <SalesReport key="wr"  
                loadPage={this.loadPage}
                date1 = {this.state.data.date1}
                date2 = {this.state.data.date2}
                />
            </div>;
            break;      
        }

        case "Recommendations_report": {
          main_element = 
            <div className="App">
              <Header key="hd" loadPage={this.loadPage} logout={this.logout} user={this.state.user}/>
              <RecommendationsReport key="rr"  
                loadPage={this.loadPage}
                date1 = {this.state.data.date1}
                day_index = {this.state.data.day_index}
                />
            </div>;
            break;      
        }
        case "Recommendations": {
          main_element = 
            <div className="App">
              <Header key="hd" loadPage={this.loadPage} logout={this.logout} user={this.state.user}/>
              <Recommendations loadPage={this.loadPage} />
            </div>;
            break;      
        }

        case "Previous_days": {
          main_element = 
            <div className="App">
              <Header key="hd" loadPage={this.loadPage} logout={this.logout} user={this.state.user} data={this.state.data}/>
              <PreviousDays 
                date = {this.state.data.date ? this.state.data.date : getCurrentDate()}
                loadPage={this.loadPage} 
                changeAppState = {this.changeState}/>
            </div>;
            break;      
        }

        case "Baking_Recommntations_Print": {
          main_element = 
            <div className="App">
              <Header key="hd" loadPage={this.loadPage} logout={this.logout} user={this.state.user} data={this.state.data}/>
              <BakingRsPrint
                loadPage={this.loadPage}
                date1 = {this.state.data.date1}
                date2 = {this.state.data.date2}
                changeAppState = {this.changeState}/>
            </div>;
            break;    
        }
        // PreviousDays
        default: {
          main_element = 
            <div className="App">
              <Header key="hd" loadPage={this.loadPage} logout={this.logout} user={this.state.user} data = {this.state.data}/>
              {this.state.currentPage}
            </div>;
          break;
        }
    }
    return ( main_element );
    //
  }
}

export default App;