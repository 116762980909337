import React from 'react';
import { useEffect, useState } from "react";
import '../css/sessions.css';
import {  transformDate, getDayIndex } from './dateTime';
import { HOST } from './data.js';
import logo from '../images/pie-logo.svg';
import SessionButtonsObj from './SessionButtonsObj';
import SquareButton from './SquareButton'

//import SessionButtonsObj from './SessionButtonsObj';

function Sessions(props) {

    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        fetchSessions()
        // setSessions(props.sessions); 
    },[props])

     //download list of sessions from database
    async function fetchSessions() {
        const url = HOST + '/sessions/' + props.date;
        try {
          let response = await fetch(url);
        
          if (response.status === 200) {
            let json = await response.json();
            setSessions(json);
  
          } else { throw new Error(`Status: ${response.status}. Something went wrong with server.`); }
          
        } catch(err) { console.error(err); }
      }

    // print baking Rs for current date
    const print_baking_Rs = (event) => {
        // move to bakingRs report
        props.loadPage("Baking_Recommntations_Print", 
            {
                date1: props.date,
                date2: props.date,
                day_index: getDayIndex(props.date)
            }
        );
    }



    return (
        <div className="today-page">
            <div className="today">
                
                <div className="center">
                    <h2 className="date white mg-top-10">{ transformDate(props.date) }</h2>
                </div>
                <SessionButtonsObj date={ props.date } sessions = {sessions} loadPage={props.loadPage}/>

                {/* Button to print the t check */}
                <div className='s-bottom-section three-buttons-in-a-row'>
                    <SquareButton 
                            key={'obtn'+ "Print-Rs"} 
                            title={ `Print R's` }  
                            button_type={"Print-baking-Rs"}
                            onClick={() => print_baking_Rs()}
                    />
                </div>
            </div>
            <div className="empty-logo">
                <img src={ logo } alt="Logo"/>
                <h1 className="grey">Pie Champion System</h1>
            </div>
        </div>
    );
}

export default Sessions;