import React, { useEffect, useState } from 'react';
import { categories } from './data';
import Category from './Category';
import Product from './Product';

import '../css/productList.css';

const ProductList = (props) => {
    // mode:
    // "RECOMMENDATIONS"
    // "OPERATIONS"
    // "PRODUCT_EDITOR"

    const [indexes, setIndexes] = useState(props.indexes);
    const products = props.product_list;

    // as soon as props changes, set of indexes is also changes
    useEffect(() => {
        setIndexes(props.indexes);
    },[props]);



    const html = [];
    let prods = [];

    categories.forEach(c => {
        prods = [];

        // select list of products for the category
        Object.values(products).forEach((p) => {
            if(p.category_id === c.category_id) 
                prods.push(p);
        });

        // check if the category contains any products
        if ( prods.length > 0) {
            // display category
            html.push(<Category key={ 'c'+ c.category_id } name={ c.name } mode='RS' />); 

            // display selected products 
            prods.forEach((p) => {
                if (indexes)
                    html.push(<Product 
                        key={ 'p'+ p.product_id } 
                        p={p} 
                        mode={ props.mode } 
                        setData={ props.setData } 
                        getData={ props.getData } 
                        getRs = { props.getRs }
                        checkRed = { props.checkRed }
                        edit = { props.edit }
                        imageClick = { props.imageClick }
                        indexes={ {product_id: p.product_id, ...indexes} } 
                    />);         
            });
        }
    });

    return (
        <div>
            {html}
        </div> 
    );

}

export default ProductList;