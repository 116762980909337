import React, {useState} from "react";

import ProductList from './ProductList'; 
import ProductEditor from './ProductEditor';
import Message from './Message';

import { all_products_obj, addNewProduct, fetchRecommendations, updateProduct } from "./data";

const Products = (props) => {
    const [is_editor_active, setEditor_state] = useState(false);
    const [flag_show_error_message, setFlag_show_error_message] = useState({show: false, message: ""});

    const [current_product, setCurrent_product] = useState({
        product_id: -1, 
        category_id: 0,
        name:"",
        short_name: "",
        baking_program_id: 2,
        provider_id: 0,
        shelf_life_info: "",
        archive: 0,
        opt: "",
        qnt_in_package: null,
        photo: "",
        description: "",
        purchase_price: null,
        selling_price: null
    });
    

    // reset all fields to empty values
    const resetEditor = () => {
        // set empty values to editor fields
        setCurrent_product({
            product_id: -1, 
            category_id: 0,
            name:"",
            short_name: "",
            baking_program_id: 2,
            provider_id: 0,
            shelf_life_info: "",
            archive: 0,
            opt: "",
            qnt_in_package: null,
            photo: "",
            description: "",
            purchase_price: null,
            selling_price: null
        });
    }


    // edit the product
    const edit = (indexes) => {
        
        const p = all_products_obj[indexes.product_id];

        setCurrent_product({
                product_id: p.product_id, 
                category_id: p.category_id,
                name: p.name,
                short_name: p.short_name,
                baking_program_id: p.baking_program_id,
                provider_id: p.provider_id,
                shelf_life_info: p.shelf_life_info,
                archive: p.archive,
                opt: p.opt,
                qnt_in_package: p.qnt_in_package,
                photo: p.photo,
                description: p.description,
                purchase_price: p.purchase_price,
                selling_price: p.selling_price
        });

        setEditor_state(true);
    }

    // check if any data have been chenged
    const isDataChanged = (_product) => {
        let is_data_change =  false;
        Object.keys(_product).forEach(field_name => {
            if(current_product[field_name] !== _product[field_name]) { 
                is_data_change = true;
                //console.log(field_name, current_product[field_name], _product[field_name]);
            }
        });
        //console.log(is_data_change);
        return is_data_change;
    }


    // save changes to database
    const saveProduct = (_product) => {

        if(!_product) return;
        // validation check
        // name should not be empty
        if( !_product.name || _product.name === "" ) { 
            setFlag_show_error_message({ show: true, message: "Type something into NAME field." });
            return; 
        }

        // short_name should not be empty
        if( !_product.short_name || _product.short_name === "" ) { 
            setFlag_show_error_message({ show: true, message: "Type something into SHORT NAME field." });
            return; 
        }

        // check if data was changed
        if(!isDataChanged(_product)) return;
       
        // add product to database
        if (_product.product_id === -1) { 
            addNewProduct(_product)  
            .then (responce => {;
                fetchRecommendations();
                resetEditor();
                setEditor_state(false);
                //setFlag_data_change(!flag_data_change);
            });
        }
        // or update existing product
        else { 
            updateProduct(_product)
            .then (responce => {
                fetchRecommendations()
                resetEditor();
                setEditor_state(false);
                //setFlag_data_change(!flag_data_change);
            });
        }
    }

    // cancel changes
    const cancel = () => {
        // reset the editor
        resetEditor();
        setEditor_state(false);  
    }

    // new product
    const newProduct = () => {
        resetEditor(); 
        setEditor_state(true);
    } 

    // close erroe message dialog
    const closeErrorMessageDialog = () => {
        setFlag_show_error_message({ show: false, message: "" });
    }

    return (
        <div className="product-page">
            <div className="pe-pl-container">
                <div className="pe-pl-header"> <h2 className="date" style={{marginLeft: "20px"}}>Products</h2>
                    <button className="small-square-button empty-green-button" onClick={() => newProduct()} style={{marginRight: "20px"}} title="Add new product"> + </button>
                </div>
                <div className="pe-pl-product-list bg-white">
                <ProductList
                        product_list = { all_products_obj }
                        edit = { edit }
                        imageClick = { edit }
                        mode = "PRODUCT_EDITOR"
                        indexes = { {} }/>
                        {/* indexes = { {flag_data_change: flag_data_change} }/> */}
                </div>
            </div>
            <ProductEditor
                product={ current_product }
                is_active={ is_editor_active }
                saveProduct = {saveProduct}
                cancel = {cancel}/>
                
            {/* MESSAGE WINDOW */}
            {/* error message dialog */}
            {flag_show_error_message.show ? 
                <Message 
                    title = "Incorrect input:"
                    message = { flag_show_error_message.message }
                    cancel_onClick = {closeErrorMessageDialog}
                    button_3="OK"
                    button_3_onClick={closeErrorMessageDialog}/>
            : ""}
      </div>
    );
}

export default Products;