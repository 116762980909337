import React, {useEffect, useState} from 'react';
import { products_obj, categories, fetchRecommendations_report } from './data.js';
import { getWeekDay, getReportFormatDate } from './dateTime.js';
import '../css/temp-check.css';

function RecommendationsReport(props) {
    const dateFrom = props.date1;
    const day_index = props.day_index;
    const [dates, setDates] = useState([]); // 4 mondays (or less) before the dateFrom
    const [data, setData]   = useState([]); // baked and wastage for selected days
    let days_total = 0;
    //

    useEffect(() => {    
        fetchRecommendations_report( dateFrom, day_index ).then((response) => {
            setDates(response[0]);  
            setData(calcData(response[1]));
        });
    }, []);
    
    // distribute data into product table
    const calcData = (row_data) => {
        const data = [];

        let index = 1;
        Object.values(products_obj).forEach(pr => {
            data.push({
                category_id: pr.category_id,
                category_name: categories[pr.category_id].name,
                product_id: pr.product_id,
                product_name: pr.name,
                index: index,
                price: pr.purchase_price,
                b1:0, w1:0,
                b2:0, w2:0,
                b3:0, w3:0,
            });
            index ++;
        });

        // calc all products - even with 0 qty
        row_data.forEach(r => {   
            data.forEach(d => {
                  if(d.product_id === r.product_id) {
                    d.b1 = parseInt(r.b1); d.b2 = parseInt(r.b2); d.b3 = parseInt(r.b3);
                    d.w1 = parseInt(r.w1); d.w2 = parseInt(r.w2); d.w3 = parseInt(r.w3);
                  }
              });
          });
        data.sort((a, b) => a.category_id - b.category_id );
        return data;
    }

    // compile all dates into string
    const listDates = () => {
        let str = " ";
        dates.map((d) => str += " "+ getReportFormatDate(d.date) + ",");
        str = str.slice(0, str.length-1);

        return str;
    }

    // the header of the table
    const tableHeader = () => {
        return (
            <section className='rr-div-5 tc-bdr-top tc-bdr-bottom tc-bdr-right tc-bdr-left bg-light-grey tc-100'>
                    <h4 className='tc-bdr-right wr-h4'>#</h4>
                    <h4 className='tc-bdr-right wr-h4'>Products</h4>

                    <div>
                        <div className='tc-bdr-right' style={{width: '100%'}}><h4 className='wr-h4'>6:00</h4></div>
                        <div className='sr-equal-div-4 tc-thin-bdr-top tc-bdr-right'>
                            <h4 className='tc-thin-bdr-right'>Avg.B</h4>
                            <h4 className='tc-thin-bdr-right'>Avg.W</h4>
                            <h4 className='tc-thin-bdr-right'>%%</h4>
                            <h4 className='green'>R's</h4>
                        </div>
                    </div>

                    <div>
                        <div className='tc-bdr-right' style={{width: '100%'}}><h4 className='wr-h4'>11:00</h4></div>
                        <div className='sr-equal-div-4 tc-thin-bdr-top tc-bdr-right'>
                            <h4 className='tc-thin-bdr-right'>Avg.B</h4>
                            <h4 className='tc-thin-bdr-right'>Avg.W</h4>
                            <h4 className='tc-thin-bdr-right'>%%</h4>
                            <h4 className='green'>R's</h4>
                        </div>
                    </div>
                    <div>
                      <div style={{width: '100%'}}><h4 className='wr-h4'>15:00</h4></div>
                        <div className='sr-equal-div-4 tc-thin-bdr-top tc-bdr-right'>
                            <h4 className='tc-thin-bdr-right'>Avg.B</h4>
                            <h4 className='tc-thin-bdr-right'>Avg.W</h4>
                            <h4 className='tc-thin-bdr-right'>%%</h4>
                            <h4 className='green'>R's</h4>
                        </div>
                    </div>
                </section>
        );
    }

    // calculate and display the body of the table
    const tableBody = () => {
        const html = [];
        let c ={};
        let category_id = -1;
        days_total = dates.length;
        if (days_total === 0) return "no data";
        //console.log(data);

        data.forEach((p) => {
            // console.log(category_id);
            if( p.category_id !== category_id && category_id !== -1) {
            
                c = {
                    category_name: categories[category_id].name,
                    category_id: categories[category_id].category_id,
                }
                html.push(display_category(c));
            }

            category_id = p.category_id;
            html.push(display_product(p));
        });


        // last category
        //console.log(category_id,  categories[category_id]);
        c = {
            category_name: categories[category_id].name,
            category_id: categories[category_id].category_id,
        }
        html.push(display_category(c));


        return <div key={"wr_tbl"}>{html}</div>;
    }

    const display_product = (d) => {
        return (
            <div key={"p" + d.product_id} className='rr-div-5 tc-100 tc-bdr-right tc-bdr-left'>
                <p className='tc-bdr-right tc-thin-bdr-bottom'>{ d.index }</p>
                <p className='tc-bdr-right tc-thin-bdr-bottom ta-left'>{ d.product_name }</p>
                
                {/* 6:00 */}
                <div className='sr-equal-div-4 tc-bdr-right tc-thin-bdr-bottom'>
                    <p className='tc-thin-bdr-right'>{ (d.b1/days_total).toFixed(2) }</p>
                    <p className='tc-thin-bdr-right'>{ (d.w1 > 0) ? (d.w1/days_total).toFixed(2) : "" }</p>
                    <p className='tc-thin-bdr-right'>{ d.b1===0 ? "" : Math.round( d.w1 / d.b1 * 100) + "%" }</p>
                    <p className='bg-light-grey green'>{ ((d.b1 - d.w1)/days_total).toFixed(2) }</p>
                </div>
                {/* 11:00 */}
                <div className='sr-equal-div-4 tc-bdr-right tc-thin-bdr-bottom'>
                    <p className='tc-thin-bdr-right'>{ (d.b2/days_total).toFixed(2) }</p>
                    <p className='tc-thin-bdr-right'>{ (d.w2 > 0) ? (d.w2/days_total).toFixed(2) : ""  }</p>
                    <p className='tc-thin-bdr-right'>{ d.b2===0 ? "" : Math.round(d.w2 / d.b2 * 100) + "%" }</p>
                    <p className='bg-light-grey green'>{ ((d.b2 - d.w2)/days_total).toFixed(2) }</p>
                </div>
                {/* 15:00 */}
                <div className='sr-equal-div-4 tc-thin-bdr-bottom'>
                    <p className='tc-thin-bdr-right'>{ (d.b3/days_total).toFixed(2) }</p>
                    <p className='tc-thin-bdr-right'>{ (d.w3 > 0) ? (d.w3/days_total).toFixed(2) : ""  }</p>
                    <p className='tc-thin-bdr-right'>{ d.b3===0 ? "" : Math.round(d.w3 / d.b3 * 100) + "%" }</p>
                    <p className='bg-light-grey green'>{ ((d.b3 - d.w3)/days_total).toFixed(2) }</p>
                </div>
            </div>
        );
    }

    const display_category = (d) => {
        const element = []; 
        element.push (
                <div key={"c" + d.category_id} className='rr-div-5 bg-light-grey bold tc-bdr-bottom tc-100 tc-bdr-right tc-bdr-left'>
                    <p className='tc-bdr-right '></p>
                    <p className='tc-bdr-right ta-left'>{ d.category_name }</p>
                    
                    {/* 6:00 */}
                    <div className='sr-equal-div-4 tc-bdr-right'>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='bg-light-grey green'></p>
                    </div>
                    {/* 11:00 */}
                    <div className='sr-equal-div-4 tc-bdr-right '>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='bg-light-grey green'></p>
                    </div>
                    {/* 15:00 */}
                    <div className='sr-equal-div-4 tc-bdr-right '>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='bg-light-grey green'></p>
                    </div>
                </div> );

                {/* EMPTY LINE */}
                element.push(
                <div key={"cte" + d.category_id} className= 'rr-div-5 tc-thin-bdr-bottom tc-100 tc-bdr-right tc-bdr-left'>
                    <p className='tc-bdr-right '>#</p>
                    <p className='tc-bdr-right  ta-left'></p>
                    
                    {/* 6:00 */}
                    <div className='sr-equal-div-4 tc-bdr-right '>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='bg-light-grey green'></p>
                    </div>
                    {/* 11:00 */}
                    <div className='sr-equal-div-4 tc-bdr-right '>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='bg-light-grey green'></p>
                    </div>
                    {/* 15:00 */}
                    <div className='sr-equal-div-4 tc-bdr-right'>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='tc-thin-bdr-right'></p>
                        <p className='bg-light-grey green'></p>
                    </div>
                </div>
                
        );
        return element;

    }



    // const response = await fetchRecommDates(dateFrom, day_index).
    //
    const day_of_week = getWeekDay(day_index).toUpperCase();
    //
    return (

        <div className='r-report wr-wastage-report '>
              {/* BUTTONS BACK AND PRINT */}
              <div className='print-button-container noprint'>
                  <button className='empty-black-button' onClick={(e) => { window.history.back() } }><div>{"<< Back"}</div></button>
                  <button className='empty-green-button' onClick={(e) => { window.print()} }><div>Print</div></button>
              </div>
              {/* HEADER */}
              <div className="wr-header"><h3>Recommendations: {day_of_week}</h3> { listDates() } </div>

            {/* TABLE */}
            <div className="wr-table">
                  { tableHeader() }
                  { tableBody() }
            </div>

        </div>
    );
}

export default RecommendationsReport;

