import React, {useState} from "react";
import '../css/temp-check.css';
import { baking_programs } from './data.js';
import { transformDate, getCurrentTime, getCurrentDate } from './dateTime.js';
import SignatureBox from './SignatureBox';


function TempCheck(props) {
    // props:
    // loadPage={this.loadPage} 
    // date =            {this.state.data.date} 
    // time =            {this.state.data.time} 
    // session_id:      props.session_id,
    // session_type_id: props.session_type_id,
    // props.data_array 


    const [flag_signature_window, setFlag_signature_window] = useState(false);
    const [signature, setSignature] = useState("")

    let str_time;

    if(props.session_type_id === 1)  {  str_time = "6:00";   }
    if(props.session_type_id === 2)  {  str_time = "11:00";  }
    if(props.session_type_id === 3)  {  str_time = "15:00";  }
    if(props.session_type_id === 4)  {  str_time = props.time; }

    const html = [];
    let element = "";

    const bp_clone = baking_programs.sort((a, b) => { return (b.wastage_time - a.wastage_time) })

    let wt = -1;

    // add the content and the header of each program
    bp_clone.forEach(bp => {

        // wastage time for different products
        if (wt !== bp.wastage_time) {
            wt = bp.wastage_time;
            html.push(html_wt_header(str_time, wt))
            
        }

        const prods = props.data_array.filter((p) => p.baking_program_id === bp.program_id)
        if (prods.length > 0) {

            element = html_section_header(bp);
            html.push(element);

            prods.forEach(p => {
                element = html_product(p)
                html.push(element)
            })

            // empty line
            element = empty_line(bp.program_id);
            html.push(element)
        }
    });
    
    // open signature box
    const sign = () => {
        setFlag_signature_window(true);
    }

    // close signature box
    const close_signature_box = () => {
        setFlag_signature_window(false);
    }

    // close signature box
    const submit_signature = (url) => {
        setSignature(url);
        setFlag_signature_window(false);
    }

    // HTML code for the header of the table section - wastage time
    function html_wt_header (str_time, wastage_period) {

        // get the number of hours
        const semicolon_pos = str_time.indexOf(":");
        const hours = parseInt(str_time.substring(0, semicolon_pos));
        const mins = str_time.substring(semicolon_pos + 1);

        const bake_time = `${hours}:${mins}`;
        const wastage_time = `${hours + wastage_period}:${mins}`;
        

        const element = 
                <section className="tc-100 tc-table-header tc-bdr-left tc-bdr-right tc-bdr-top" key={"w_p" + wastage_period}>
                {/*TABLE HEADER */}
                    <div className="tc-three">
                        <h3> BAKE: { bake_time }</h3>
                        <p className="x-small">Circle the timing tag used:</p>
                        <div className="tc-shapes">
                            <img src="./media/shapes.png"></img>
                            <div>Blank</div>
                        </div>
                    </div>
                    <div><h3 className="tc-bdr-left"> WASTE: { wastage_time }</h3></div>
                </section>

        return element; 
    }

    // HTML code for each baking program 
    function html_section_header (bp) {
        let element = [];
        
        element.push( 
            <section className="baking-program tc-100 tc-table-header tc-bdr-left tc-bdr-right tc-bdr-top" key={"tcpth" + bp.program_id }>
                <div>
                    <div className="tc-three">
                        <h4 className="tc-thin-bdr-bottom" style={{paddingLeft: "5px"}}>{bp.name}</h4>
                        <h4 className="tc-thin-bdr-bottom">{bp.temperature}</h4>
                        <div className="tc-thin-bdr-bottom"></div>
                    </div>
                    <h4 style={{textAlign: "right", marginRight: "5px"}}>Savoury Item Check</h4>
                    
                    {bp.program_id === 2 ? <h4 className="tc-thin-bdr-bottom tc-thin-bdr-top" style={{textAlign: "right", paddingRight: "5px"}}>Pie Item Check</h4> : ""}
                    {bp.program_id === 2 ? <h4 style={{textAlign: "right", paddingRight: "5px"}}>Chicken Item Check</h4> : ""}

                    {bp.program_id === 4 ? <h4 className="tc-thin-bdr-bottom tc-thin-bdr-top" style={{textAlign: "right", paddingRight: "5px"}}>One product in the middle of the tray. If chicken in this bake check one of those.</h4> : ""}
                    {bp.program_id === 4 ? <h4 style={{textAlign: "right", paddingRight: "5px"}}>One product o outside of tray. If chicken in this bake check one of those.</h4> : ""}

                </div>
                <div className="tc-two-cols tc-bdr-left">
                    <h5 className="tc-thin-bdr-right tc-thin-bdr-bottom">Temp</h5>
                    <h5 className="tc-thin-bdr-bottom">Checked by</h5>
                    <h5 className="tc-thin-bdr-right " style={{textAlign: "right", paddingRight: "5px"}}>°C</h5>
                    <div><img className="tc-small-signature" src={signature}/></div>
                    
                    {bp.program_id === 2 || bp.program_id === 4 ? <h5 className="tc-thin-bdr-right tc-thin-bdr-bottom tc-thin-bdr-top" style={{textAlign: "right", paddingRight: "5px"}}>°C</h5>: ""}
                    {bp.program_id === 2 || bp.program_id === 4 ? <div className="tc-thin-bdr-bottom tc-thin-bdr-top"><img className="tc-small-signature" src={signature}/></div>: ""}
                    {bp.program_id === 2 || bp.program_id === 4 ? <h5 className="tc-thin-bdr-right" style={{textAlign: "right", paddingRight: "5px"}}>°C</h5>: ""}
                    {bp.program_id === 2 || bp.program_id === 4 ? <div ><img className="tc-small-signature" src={signature}/></div>: ""}
                </div>
                
            </section>
        )
        // sub title of the table
         element.push( <div  className="tc-100 tc-table-header tc-bdr-left tc-bdr-right tc-bdr-top" key={"tcpt" + bp.program_id}>
                    <div className="tc-tree-pr">
                        <div className="tc-thin-bdr-right"></div>
                        <p className="tc-number tc-thin-bdr-right small">Bake</p>
                        <p className="tc-number small">Tick</p>
                    </div>
                    <div className="tc-two-cols ">
                        <p className="tc-bdr-left tc-number small">Reason for change</p>
                        <p className="tc-thin-bdr-left tc-number small">Waste</p>
                    </div>
                </div>
         )
        return element;
    }

    // HTML code for each product
    function html_product (p)  {
        let qty = "";
        let reason = "";

        if( p.baked_QTY === p.r_QTY ) 
                    if(p.r_QTY === 0) qty = "-"
                    else qty = p.r_QTY; 
        else {
            qty = <div><span className="crossed">{p.r_QTY}</span><span className="hand-written">{p.baked_QTY}</span></div>
            if(p.baked_QTY > 0) reason = "D"; else reason = "OS";
        }

        // display product
        const element = <div  className="tc-product tc-100 tc-table-header tc-bdr-left tc-bdr-right tc-thin-bdr-top" key={"tcp" + p.product_id}>
                            <div className="tc-tree-pr">
                                <p className="tc-thin-bdr-right">{p.name}</p>
                                <div className="tc-number tc-thin-bdr-right">{ qty }</div>
                                <div>{p.baked_QTY  > 0 ? <div className="blue tc-number">&#x2713;</div> : ""}</div>
                            </div>
                            <div className="tc-two-cols">
                                <div className="tc-bdr-left"><span className="hand-written">{ reason }</span></div>
                                <p className="tc-thin-bdr-left tc-number"><span className="hand-written">{ (p.wastage_QTY === 0) ? "" :  p.wastage_QTY}</span></p>
                            </div>
                        </div>
        return element;
    }

    // draw empty line after each baking programm
    function empty_line(id) {
        const element = <div className="tc-100 tc-empty-line tc-bdr-left tc-bdr-right tc-thin-bdr-top" key={"tc_e" + id}></div>
        return element
    }


    // HTML
    return (
        // HEADER
        <div className = "r-report temp-check portrait" key = {props.date + props.time}>
            {/* BUTTONS BACK AND PRINT */}
            {props.session_id === -1 ?  "" :
                <div className='print-button-container noprint'>
                    <button className='empty-black-button' onClick={(e) => { window.history.back() } }><div>{"<< Back"}</div></button>
                    <button className='empty-green-button' onClick={(e) => { sign(); } }><div>Sign</div></button>
                    <button className='green-button' onClick={(e) => { window.print(); } }><div>Print</div></button>
                </div>
            }

            {/* SIGNATURE BOX */}
            { flag_signature_window ? < SignatureBox
                cancel_onClick={ close_signature_box }
                submit_onClick={ submit_signature }/> : ""}

            {/* HEADER TEXT */}
            <div className="tc-header">
                <div>
                    <h3 className="tc-title">Bake recommendations: <span className="light black"> {transformDate(props.date)} </span></h3>
                    <p>Printed: {transformDate(getCurrentDate())} at {getCurrentTime()}</p>
                </div>
                <img src="./favicon.ico"></img>
                <p className="small join-2">
                    Temperature check &gt; 76°C: if the temp reads LESS THEN 76° 
                    put back in the oven and continue to cook +time re-check 
                    them and record next to Item.
                </p> 
            </div> 

            {/* CONTENT */}
            <div>{html}</div>

            {/* FOOTER */}
            <div className="tc-100 tc-comment tc-bdr-left tc-bdr-right">
                <p>Comment Re: row waste</p>
                <img className="tc-large-signature" src={signature} />
            </div>
            <div className="tc-100 tc-bdr-left tc-bdr-right tc-thin-bdr-top footer" style={{textAlign: "right"}}><p style={{paddingRight: "1rem"}}>Checked and entered by</p></div>
            <div className="tc-100 tc-bdr-top tc-bdr-left tc-bdr-right page-break" ></div>
        </div>
    );
}

export default TempCheck;